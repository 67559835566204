#contact-detail-page {
    #contact-detail-form[data-language="fr_BE"],
    #contact-detail-form[data-language="nl_BE"] {
        #requestInterest-col {
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                display: block;
            }
        }
        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Usage_Solutions"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }
        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                [data-dropdown-value="Dealer_Sales_Support"],
                [data-dropdown-value="Dealer_Managed_Service"] {
                    display: block;
                }
            }
        }
        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"]{
                    display: block;
                }
            }
            .row-customer-service {
                display: flex;
            }
            [data-customer-service-item="CustomerNumber"],
            [data-customer-service-item="ContractNumber"] {
                display: block;
            }
        }

        #imprint-grenke-ag, .imprint-row, .phone-contact-number-grenke-ag {
            display: block;
        }

        [data-form-input-name="VATNumber"] {
            display: block;
        }
    }
}