// styles for da_DK

#contact-detail-page {
    #contact-detail-form[data-language="da_DK"] {
        // show interests-values
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"] {
            #requestTopic-col {
                display: block;
            }
        }

        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Usage_Solutions"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                display: block;
            }

            #requestTopic-col {
                [data-dropdown-value="Dealer_Sales_Support"],
                [data-dropdown-value="Dealer_Managed_Service"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Customer_Service"]{
            #requestTopic-col {
                display: block;

                [data-dropdown-value="Leasing"]{
                    display: block;
                }
            }
        }

        &[data-form-interest="Customer_Service"][data-form-topic="Leasing"] {
            .row-customer-service {
                display: flex;
            }
            [data-customer-service-item="CustomerNumber"],
            [data-customer-service-item="ContractNumber"] {
                display: block;
            }
        }

        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"] {
                    display: block;
                }
            }

            &[data-form-topic="Leasing_And_Rental"] {
                .upload-row {
                    display: flex;
                    [data-upload-title="supplier"], [data-upload-btn="supplier"] {
                        display: block;
                    }
                }
            }
        }

        #imprint-grenke-ag, .imprint-row, .phone-contact-number-grenke-leasing {
            display: block;
        }

        [data-form-input-name="CompanyRegistrationNo"] {
            display: block;
        }
    }
}