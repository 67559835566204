.stage-module-text,
.stage-module-highlight,
.stage-module-adhoc,
.stage-module-blue-box,
.stage-module-empty {
  @include std-bottom-margin(false);
}

.stage-module-default {
  margin-bottom: calc(#{$bottom-gap-module-xs});
  @include media-breakpoint-up(sm) {
    margin-bottom: 50px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 100px;
  }
}

.stage-module-adhoc {
  .adhoc-container {
    position: relative;
    z-index: 1;
    margin-top: 40px;
    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 60px;
    }
    .adhoc-box-container {
      @include media-breakpoint-up(xl) {
        width: 100%;
      }
      .adhoc-box-wrapper {
        background-color: $color-grenke-black;
        padding: 20px;
        @include media-breakpoint-up(lg) {
          padding: 30px;
        }
        @include media-breakpoint-up(xl) {
          margin-top: -110px;
        }
        .adhoc-box-headline {
          margin-bottom: 20px;
        }
        .adhoc-box-date {
          margin-bottom: 10px;
        }
      }
    }
    .adhoc-headline {
      margin-top: 25px;
      max-height: 85px;
      @include media-breakpoint-up(md) {
        // 38px line-height
        max-height: calc(3 * 38px);
        margin-top: 0;
      }
      @include media-breakpoint-up(xl) {
        max-height: 120px;
      }
    }
    .adhoc-text {
      max-height: 100px;
      margin-top: 10px;
      @include media-breakpoint-up(sm) {
        max-height: 120px;
        margin-top: 30px;
      }
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
    .adhoc-overview-button {
      margin-top: 10px;
      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }
    .adhoc-button {
      margin-top: 15px;
    }
    .adhoc-detail-button {
      @include media-breakpoint-only(xs) {
        margin-top: 0;
      }
    }
    .adhoc-line {
      margin-top: 30px;
      border-bottom: solid 1px $color-grenke-black;
      @include media-breakpoint-up(md) {
        margin-top: 50px;
      }
      @include media-breakpoint-up(xl) {
        margin-top: 30px;
      }
    }
  }
}

.stage-module-suggestion {
  .suggestion-module {
    margin-top: 40px;
    @include media-breakpoint-up(xl) {
      margin-top: -80px;
    }
  }
  .stage-module {
    .grid-container {
      @include media-breakpoint-up(xl) {
        & > .row {
          margin-top: 80px;
        }
      }
      .content-box-wrapper {
        @include media-breakpoint-up(xl) {
          max-height: 440px;
        }
      }
    }
  }
  .stage-module-new {
    .text-slide-container {
      @include media-breakpoint-up(xl) {
        margin-top: -80px;
        max-height: 440px;
      }
    }
    .text-slide-outer {
      @include media-breakpoint-up(xl) {
        max-height: 360px;
      }
    }
  }
}

.stage-module-text {
  .text-module {
    margin-top: 40px;
    //border-bottom: solid 1px $color-grenke-grey-light;
    .copytext-box + .copytext-box {
      margin-top: 20px;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }
  .text-border-bottom {
    width: 100%;
    height: 1px;
    background-color: $color-grenke-black;
    margin-top: 40px;
    @include media-breakpoint-up(xl) {
      margin-top: 50px;
    }
  }
}

.stage-module-highlight {
  .highlight-module {
    margin-top: 40px;
    @include media-breakpoint-up(xl) {
      margin-top: -80px;
    }
  }
  .stage-module {
    .grid-container {
      @include media-breakpoint-up(xl) {
        & > .row {
          margin-top: 80px;
        }
      }
      .content-box-wrapper {
        @include media-breakpoint-up(xl) {
          max-height: 440px;
        }
      }
    }
  }
  .stage-module-new {
    .text-slide-container {
      @include media-breakpoint-up(xl) {
        margin-top: -80px;
        max-height: 440px;
      }
    }
    .text-slide-outer {
      @include media-breakpoint-up(xl) {
        max-height: 360px;
      }
    }
  }
}

.stage-module {
  position: relative;
  .dotdotdot-row {
    min-height: 0;
    overflow: hidden;
  }
  .bg-image {
    overflow: hidden;
    height: 196px;
    img {
      width: 100%;
    }
    @include media-breakpoint-up(sm) {
      height: 302px;
    }
    @include media-breakpoint-up(md) {
      height: 403px;
    }
    @include media-breakpoint-up(lg) {
      height: 536px;
    }
    @include media-breakpoint-up(xl) {
      height: 645px;
    }
  }
  .grid-container {
    margin-top: -30px;
    @include media-breakpoint-up(sm) {
      margin-top: -45px;
    }
    @include media-breakpoint-up(md) {
      margin-top: -60px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -70px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: -645px;
      & > .row {
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .content-box-wrapper {
      background-color: $color-grenke-white;
      padding: 20px 30px 30px;
      @include media-breakpoint-up(sm) {
        padding: 30px;
      }
      @include media-breakpoint-up(md) {
        padding: 40px;
      }
      @include media-breakpoint-up(xl) {
        max-height: 500px;
      }
    }
  }
  .breadcrumb-container {
    margin-bottom: 20px;
    color: $color-grenke-grey-dark-text;
    font-weight: 300;
    @include media-breakpoint-between(xs, sm) {
      font-size: 14px;
      margin-bottom: 15px;
    }
    .current-breadcrumb {
      color: $color-grenke-black;
    }

    &:hover {
      color: $color-grenke-black;
    }
  }
  .slider-arrow-container {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.stage-form {
  &-container {
    margin-top: -30px;
    @include media-breakpoint-only(sm) {
      margin-top: -40px;
    }
    @include media-breakpoint-only(md) {
      margin-top: -60px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -70px;
    }
    &:after {
      content: "";
      display: block;
      position: relative;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 35px solid transparent;
      border-right: 35px solid transparent;
      border-top: 25px solid $color-grenke-black;
    }
  }
  &-content-wrapper {
    background-color: $color-grenke-black;
    padding: 30px 0;
    @include media-breakpoint-only(xl) {
      padding: 40px 0;
    }

    .formRow .placeholder {
      top: 13px;
      left: 0;
    }

    .formRow--dropdown-wrapper {
      &.active {
        .placeholder {
          top: -22px;
          left: -10px;
        }
      }
    }
  }
  #contact-content-page &-container {
    .col-icon + .col-headline {
      margin-top: 20px;
    }
    .col-headline + .col-text {
      margin-top: 10px;
      @include media-breakpoint-up(md) {
        margin-top: 20px;
      }
    }
    .contact-content-icon {
      width: 48px;
      height: 35px;
    }
  }
  #contact-content-page &-container + .history-back-container {
    margin-top: 20px;
    @include std-bottom-margin(false);
  }
  #contact-content-page.success &-container {
    .contact-content-icon {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_checkmark_1_white.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.stage-module-new {
  position: relative;
  &.stage-slide-new .text-slide {
    opacity: 0 !important;
    visibility: hidden !important;
  }
  .text-slide-container {
    @include media-breakpoint-down(lg) {
      box-shadow: 0px 0px 0px 2px $color-grenke-grey-background;
    }
  }
  .btn-box {
    width: 100%;
  }
  .image-slide {
    width: 100%;
    overflow: hidden;
    height: 196px;
    @include media-breakpoint-up(sm) {
      height: 302px;
    }
    @include media-breakpoint-up(md) {
      height: 403px;
    }
    @include media-breakpoint-up(lg) {
      height: 536px;
    }
    @include media-breakpoint-up(xl) {
      height: 645px;
    }
    &-container,
    &-wrapper {
      width: 100%;
    }
  }
  .text-slide {
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    // set all slides same height
    @include media-breakpoint-up(lg) {
      height: auto;
    }
    &-container-outer {
      margin-top: -30px;
      @include media-breakpoint-up(sm) {
        margin-top: -45px;
      }
      @include media-breakpoint-up(md) {
        margin-top: -60px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -70px;
      }
      @include media-breakpoint-up(xl) {
        margin-top: -645px;
      }
    }
    &.swiper-slide-active {
      opacity: 1 !important;
      visibility: visible !important;
    }
    &-wrapper {
      // for resize from mobile to desktop
      @include media-breakpoint-up(lg) {
        /* height: auto !important; */
      }
    }
    &-container {
      background-color: $color-grenke-white;
      padding: 20px 30px 30px;
      @include media-breakpoint-up(sm) {
        padding: 30px;
      }
      @include media-breakpoint-up(md) {
        padding: 40px;
      }
      @include media-breakpoint-up(xl) {
        max-height: 500px;
      }
    }
    &-outer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include media-breakpoint-up(xl) {
        max-height: 420px;
      }
      .breadcrumb-container {
        margin-bottom: 20px;
        color: $color-grenke-grey-dark-text;
        font-weight: 300;
        @include media-breakpoint-between(xs, sm) {
          font-size: 14px;
          margin-bottom: 15px;
        }
        .current-breadcrumb {
          color: $color-grenke-black;
        }
      }
      .content-wrapper {
        @include media-breakpoint-up(xl) {
          overflow: hidden;
        }
      }
      .btn-wrapper {
        margin-top: 30px;
      }
      @include media-breakpoint-only(xs) {
        .primary-flag + .primary-flag {
          margin-top: 20px;
        }
        .primary-flag + .secondary-flag {
          margin-top: 20px;
        }
        .primary-flag + .tertiary-flag {
          margin-top: 20px;
        }
        .secondary-flag + .primary-flag {
          margin-top: 20px;
        }
        .secondary-flag + .secondary-flag {
          margin-top: 20px;
        }
        .secondary-flag + .tertiary-flag {
          margin-top: 20px;
        }
        .tertiary-flag + .primary-flag {
          margin-top: 20px;
        }
        .tertiary-flag + .secondary-flag {
          margin-top: 20px;
        }
        .tertiary-flag + .tertiary-flag {
          margin-top: 20px;
        }
      }
      // because the old-version has a quite same behaviour
      ul {
        display: none;
      }
    }
  }
  .indicator-wrapper {
    .dot {
      top: auto;
      border: 1px solid $color-grenke-white;
    }
    @include media-breakpoint-up(xl) {
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
  }
  .arrow-wrapper {
    z-index: 1;
    .slider-arrow {
      padding: 0;
      position: relative;
      width: 33px;
      height: 33px;
      background-size: contain;
      background-repeat: no-repeat;
      @include media-breakpoint-down(sm) {
        width: 19px;
      }
    }
    .slider-arrow.prev {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_sliderarrow-outline-left_1_black.svg");
    }
    .slider-arrow.next {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_sliderarrow-outline-right_1_black.svg");
    }
  }
  .slider-arrow-container-sm-lg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    z-index: 1;
  }
}

.stage-module-blue-box {
  .stage-blue-box-addon {
    position: relative;
    z-index: 1;
    margin-top: 40px;
    @include media-breakpoint-up(xl) {
      margin-top: -40px;
    }
    &:after {
      content: "";
      display: block;
      position: relative;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 35px solid transparent;
      border-right: 35px solid transparent;
      border-top: 25px solid $color-grenke-black;
    }
    .stage-box-container {
      background-color: $color-grenke-black;
      padding: 30px 0;
      margin-top: 10px;
      @include media-breakpoint-up(xl) {
        padding: 40px 0;
      }
      h3,
      .copy-1 {
        color: $color-grenke-white;
      }
      h3 + .copy-1 {
        margin-top: 20px;
      }
      .copy-1.white {
        p a {
          color: $color-grenke-white;
        }
      }
    }
  }
}
