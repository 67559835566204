.dashboard-ir {
    @include std-bottom-margin(false);

    .fact-sheet-content {
        padding-top: 30px;
        h4 + .copy {
            margin-top: 15px;
            @include media-breakpoint-up(sm) {
                margin-bottom: 15px;
            }
        }
    }
    .cell-row {
        div.border-right {
            border-right: 1px solid $color-grenke-black;
        }

        div.border-right-none {
            border-right: none;
        }

        div.border-right-md {
            @include media-breakpoint-up(md) {
                border-right: 1px solid $color-grenke-black;
            }
        }

        div.border-right-lg {
            @include media-breakpoint-up(lg) {
                border-right: 1px solid $color-grenke-black;
            }
        }

        div.border-right-md-none {
            @include media-breakpoint-up(md) {
            border-right: none;
            }
        }

        div.border-right-lg-none {
            @include media-breakpoint-up(lg) {
                border-right: none;
            }
        }

        div.border-bottom-lg-none {
            @include media-breakpoint-up(lg) {
                border-bottom: none !important;
            }
        }

        div.border-top {
            border-top: 1px solid $color-grenke-black;
        }

        div.border-bottom {
            border-bottom: 1px solid $color-grenke-black;
        }

        .copy{
            min-height: 40px;

            @include media-breakpoint-up(lg) {
                min-height: 80px;
            }
        }
    }

    h5.date {
        color: $color-grenke-black;
    }

    .fact-sheet-content {
        padding: 30px 15px;
    }
}
