.contact-teaser-module {
    @include std-bottom-margin(false);
    .heading-text {
        padding: 50px 20px 50px 50px;

        &:after {
            content:"";
            position: absolute;
            right: -30px;
            top: 50%;
            width: 0;
            height: 0;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            border-left: 17px solid $color-grenke-grey-background;
            margin-top: -17px;
            z-index: 1;
        }
    }

    .search-form {
        background-color: $color-grenke-black;
        color: #fff;
        height: 100%;
        width: calc(100% + 1px);
        position: relative;
        .wrapper {
            padding: 30px 80px;
            &.blue-theme {
            }
        }
    }
    .mla-teaser & {
        .teaser-headline {
            margin-bottom: 10px;
        }
        .background-box {
            background-color: $color-grenke-black !important;
            padding: 40px 0;
        }
        h3 {
            color: $white;
            font-size: 22px;
            line-height: 30px;
            @include media-breakpoint-up(sm) {
                font-size: 32px;
                line-height: 44px;
            }
        }
        .search-form {
            background-color: transparent;
            p {
                font-size: 14px;
                @include media-breakpoint-up(sm) {
                    font-size: 16px;
                }
            }
        }
        .formRow--row + .formRow--row {
            margin-top: 40px;
        }

    }
}
// formfield override
#contact-teaser {
    .formRow {
        label.error, span.error {
            bottom: auto;
            top: 40px;
        }
        &--input.error {
            margin-bottom: 20px;
        }
    }
}
