.diagram-module {
    margin-bottom: 40px;
    @include media-breakpoint-up(sm) {
        margin-bottom: 50px;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 50px;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 80px;
    }

    .diagram {

        display: flex;
        flex-direction: column;
        position: relative;

        .headline {
            border-bottom: 1px solid #000000;
        }

        .diagrams {
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;
            flex-wrap: wrap-reverse;
            flex-direction: column-reverse;
            @include media-breakpoint-up(md) {
                &.highlight-position-left {
                    flex-direction: row-reverse;
                }
                &.highlight-position-right {
                    flex-direction: row;
                }
            }

            .scroll-wrapper {
                display: flex;
            }

            .diagram-highlight {
                text-align: left;
                align-self: center;
                flex: 100%;
                max-width: 100%;
                @include media-breakpoint-up(md) {
                    flex: calc(80% - 50px) 0 0;
                    max-width: calc(80% - 50px);
                }
                @include media-breakpoint-up(lg) {
                    flex: calc(60% - 50px) 0 0;
                    max-width: calc(60% - 50px);
                }
                @include media-breakpoint-up(xl) {
                    flex: calc(40% - 50px) 0 0;
                    max-width: calc(40% - 50px);
                }

                &-value, &-left {
                    font-size: 42px;
                    font-weight: 800;
                }

                &-right {
                    font-size: 28px;
                    font-weight: 800;
                }

                & .diagram-highlight-description {
                    font-size: 20px;
                }

                & .diagram-highlight-flexer {
                    display: flex;
                    align-items: center;
                }

                @include media-breakpoint-up(md) {
                    &-value, &-left {
                        font-size: 62px;
                    }
                    &-right {
                        font-size: 40px;
                    }
                    & .diagram-highlight-description {
                        font-size: 28px;
                    }
                }

                .diagram-trend-up {
                    rotate: -45deg;
                }

                .diagram-trend-down {
                    rotate: 45deg;
                }

                .hidden {
                    height: 48px;
                    background-color: transparent;
                    color: transparent;
                }
            }
        }

        &.diagram-type-horizontal {
            .scroll-wrapper {
                width: 100%;
            }

            .diagrams {
                align-items: stretch;
                @include media-breakpoint-up(md) {
                    align-items: center;
                }
            }

            .diagram-bars {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 20px;
                flex: 100% 1 0;
            }

            .diagram-wrapper {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: center;
                max-width: 100%;
                gap: 10px;
            }

            .diagram-value {
                font-weight: 600;
            }

            .diagram-divider {
                display: none;
            }

            .diagram-bar {
                transition: all 1s ease;
                height: 40px;
                max-width: 0;
                flex: 1;
                background-color: #ececed;
            }
        }

        &.diagram-type-vertical {
            .scroll-wrapper {
                @include media-breakpoint-down(sm) {
                    overflow: scroll;
                    width: 100%;
                }
            }

            .diagram-bars {
                display: flex;
                justify-content: flex-end;
                gap: 20px;
                flex: auto 0 1;
                height: 250px;
            }

            .highlight-position-left {
                .diagram-bars {
                    justify-content: flex-start;
                }
            }

            .diagram-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                max-width: 100%;
            }

            .diagram-value {
                font-weight: 600;
            }

            .diagram-divider {
                border-bottom: 1px solid black;
                width: 100%;
            }

            .diagram-bar {
                transition: all 1s ease;
                width: 40px;
                max-height: 0;
                flex: 1;
                background-color: #ececed;
            }

        }

        :is(.diagram-type-vertical, .diagram-type-horizontal) .diagrams {
            @include media-breakpoint-up(md) {
                &.highlight-position-left {
                    justify-content: space-between;
                }
                &.highlight-position-right {
                    justify-content: space-between;
                }
            }
        }

        &.diagram-type-pie, &.diagram-type-donut {

            .pie {
                width: 200px;
                height: 200px;
            }

            .pie-legend-item {
                display: flex;
                align-items: center;
            }

            .pie-legend-item::before {
                content: '';
                background-color: var(--legend-item-color);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 5px;
            }

            .pie-legend {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 15px;
                position: absolute;
                bottom: 10px;
                left: 50%;
                translate: -50% 0;
                width: 80%;
            }
        }
    }
}
