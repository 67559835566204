#optin-optout-page {
  .back-to-home-container {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .optout-form {
    margin-top: -20px;
    @include std-bottom-margin(false);
    @include media-breakpoint-up(sm) {
      margin-top: -40px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -80px;
    }
    button[type="submit"] {
      cursor: pointer;
    }
    .optout-error {
      @include formfield-error;
      display: none;
    }
  }
}
