.highlight-module {
  position: relative;
  z-index: 1;
  @include std-bottom-margin(false);
  .background-wrapper {
    background-color: $color-grenke-grey-background;
    padding: 0 0 30px 0;
    @include media-breakpoint-up(sm) {
      padding: 30px 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
  }
  .highlight-section {
    background-color: $color-grenke-black;
    height: 52px;
    display: flex;
    align-items: center;
    padding: 0 25px 0 30px;
    position: relative;
    justify-content: space-between;
    @include media-breakpoint-up(sm) {
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 52px;
      justify-content: normal;
    }
    @include media-breakpoint-up(md) {
      width: 83px;
    }
    @include media-breakpoint-up(xl) {
      width: 68px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 30px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $color-grenke-black;
      @include media-breakpoint-up(sm) {
        bottom: auto;
        top: 40px;
        right: -10px;
        border-right: none;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid $color-grenke-black;
      }
      @include media-breakpoint-up(lg) {
        top: 50px;
      }
    }
    .icon-wrapper {
      width: 30px;
      height: 30px;
      justify-self: end;
      /* TODO add icon */
      &.icon-whitepaper {
        background: url("../website/medias/v2/icons/GRENKE_Icon_sign_lightbulb_1.svg") no-repeat center;
        background-size: contain;
      }
      &.icon-info {
        background: url("../website/medias/v2/icons/GRENKE_Icon_sign_information_1_white.svg") no-repeat center;
        background-size: contain;
      }
      &.icon-location {
        background: url("../website/medias/v2/icons/GRENKE_Icon_sign_compass_1.svg") no-repeat center;
        background-size: contain;
      }
      @include media-breakpoint-up(sm) {
        align-self: flex-start;
        margin: 25px auto 0;
        width: 40px;
        height: 40px;
      }
      @include media-breakpoint-up(md) {
        margin: 25px auto 0;
        width: 50px;
        height: 50px;
      }
      @include media-breakpoint-up(lg) {
        margin: 30px auto 0;
      }
    }
  }
  .highlight-content {
    padding: 20px 30px 30px;
    @include media-breakpoint-up(sm) {
      padding: 0;
    }
  }
  .highlight-btn {
    padding: 0 30px;
    width: 100%;
    &:active,
    &:focus {
      outline: none;
    }
    @include media-breakpoint-up(sm) {
      padding: 30px 0 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
}
