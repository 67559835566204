#contact-detail-page {
  #contact-detail-form[data-language="ro_RO"],
  #contact-detail-form[data-language="en_RO"] {
    // show interests-values
    #requestInterest-col {
      [data-dropdown-value="Cooperation"],
      [data-dropdown-value="Consulting"],
      [data-dropdown-value="Offers"],
      [data-dropdown-value="Customer_Service"],
      [data-dropdown-value="Press"],
      [data-dropdown-value="Career"],
      [data-dropdown-value="Other"] {
        display: block;
      }
    }

    // show topic-dropdown
    &[data-form-interest="Cooperation"],
    &[data-form-interest="Consulting"],
    &[data-form-interest="Offers"],
    &[data-form-interest="Customer_Service"] {
      #requestTopic-col {
        display: block;
      }
    }

    &[data-form-interest="Consulting"] {
      #requestTopic-col {
        [data-dropdown-value="Liquidity_Entrepreneurs"],
        [data-dropdown-value="Asset_Financing"],
        [data-dropdown-value="Usage_Solutions"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Cooperation"] {
      #requestTopic-col {
        [data-dropdown-value="Dealer_Sales_Support"],
        [data-dropdown-value="Dealer_Managed_Service"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Offers"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing_And_Rental"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Customer_Service"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing"] {
          display: block;
        }
      }

      .row-customer-service {
        display: flex;

        [data-customer-service-item="CustomerNumber"],
        [data-customer-service-item="ContractNumber"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Offers"][data-form-topic="Leasing_And_Rental"] {
      .upload-row {
        display: flex;

        [data-upload-title="bank"], [data-upload-btn="bank"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Press"] {
      #optin-default {
        display: block;
      }
    }

    // show extra fields
    [data-form-input-name="VATNumber"] {
      display: block;
      margin-top: 40px;
    }


    // show default number
    .phone-contact-number-grenke-ag {
      display: block;
    }

    .imprint-row {
      display: flex;

      #imprint-grenke-ag {
        display: block;
      }
    }
  }
}
