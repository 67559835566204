#contact-detail-page {
  #contact-detail-form {
    // hide interest and topic values
    #requestTopic-col,
    #requestInterest-col {
      // hide all topics
      [data-dropdown-value] {
        display: none;
      }
    }

    // hide topic-dropdown
    #requestTopic-col {
      display: none;
    }

    // hide all phone-numbers
    .phone-contact-number {
      display: none;
    }

    // hide imprint
    .imprint-row {
      display: none;
    }

    // hide imprint text
    [id^="imprint-"] {
      display: none;
    }

    &[data-form-interest="Career"] {
      .row-phone-request {
        display: none;
      }
    }

    // theme dropdown end
    //
    // initial hide Fields
    //
    [data-form-input-name="placeholder"],
    [data-form-input-name="Country"],
    [data-form-input-name="Address"],
    [data-form-input-name="City"],
    [data-form-input-name="VATNumber"],
    [data-form-input-name="CompanyRegistrationNo"],
    [data-form-input-name="TaxID"] {
      display: none;
    }

    #optin-dataprotectionagreement {
      display: none;
    }

    #optin-default + #optin-dataprotectionagreement {
      margin-top: 20px;
    }

    [data-form-input-name="VATNumber"],
    [data-form-input-name="CompanyRegistrationNo"],
    [data-form-input-name="TaxID"] {
      margin-top: 40px;
    }

    // hide upload
    .upload-row, [data-upload-title], [data-upload-btn] {
      display: none;
    }

    // show/hide Customer_Service Fields
    //
    .row-customer-service, [data-customer-service-item] {
      display: none;
    }

    &[data-form-interest="Career"] {
      [data-form-input-name-row] {
        display: none;
      }
    }

    // show/hide Press Fields
    //
    &[data-form-interest="Press"] {
      [data-form-input-name-row] {
        display: none;
      }

      #optin-default {
        display: none;
      }
    }

    // show/hide Investor_Relations Fields
    //
    .investor-relations-row, #optin-ir {
      display: none;
    }

    #phone-time-wrapper-js {
      .time-slot {
        display: none;
      }
    }

    &[data-form-interest="Investor_Relations"],
    &[data-form-interest="Press"],
    &[data-form-interest="Career"],
    &[data-form-interest="Other"],
    &[data-form-topic="Factoring"],
    &[data-form-topic="Leasing"],
    &[data-form-topic="Leasing_And_Rental"],
    &[data-form-topic="Usage_Solutions"],
    &[data-form-topic="Asset_Financing"],
    &[data-form-topic="Liquidity_Entrepreneurs"],
    &[data-form-topic="Liquidity_Startups"],
    &[data-form-topic="Liquidity_Public"],
    &[data-form-topic="Individual"],
    &[data-form-topic="Loans"],
    &[data-form-topic="Accounts"],
    &[data-form-topic="Investment"],
    &[data-form-topic="General"],
    &[data-form-topic="Banking"],
    &[data-form-topic="Banking_Services"],
    &[data-form-topic="Business_Banking"],
    &[data-form-topic="Startup_Financing"],
    &[data-form-topic="Dealer_Advanced_Financing"],
    &[data-form-topic="Dealer_Sales_Support"],
    &[data-form-topic="Dealer_Managed_Service"],
    &[data-form-topic="Liquidity_Selfemployed"],
    &[data-form-topic="Liquidity_Institutions"] {
      .imprint-row {
        display: flex;
      }
    }

    // MLA Default
    &[data-campaign-name="mla"] {
      .phone-contact-number-grenke-leasing-leasingtest {
        display: block;
      }
    }
  }
}
