/*
	Styles for text image modul
*/

.text-image {
	width: 100%;
    @include std-bottom-margin(false);
	&.box.default {
		height: auto;
		@include media-breakpoint-up(lg) {
			@include std-bottom-margin(false, 50px);
		}
	}
	&.box.mirrored {
		height: auto;
		@include media-breakpoint-up(lg) {
			@include std-bottom-margin(false, 50px);
		}
	}
	&.detached {
		height: auto;
	}
	&.long {
		height: auto;
		.text-box-container {
			.button-box {
				padding-top: 30px;
			}
		}
		&.long-quote {
			.button-box {
				padding-top: 0;
			}
		}
	}
	.copy-text {
		margin-bottom: 0;
	}
	.button-box {
		padding-top: 30px;
	}
}

.text-box-container {
	.mirrored & {
		@include media-breakpoint-up(lg) {
			max-height: 425px;
		}
	}
	.detached & {
		background-color: transparent;
		padding-top: 30px;
		@include media-breakpoint-up(sm) {
			padding-top: 40px;
		}
		@include media-breakpoint-up(xl) {
			padding-top: 50px;
		}
	}
	.two-column & {
		padding: 30px 30px 15px 30px;
		@include media-breakpoint-up(xl) {
			min-height: 270px;
		}
	}
}

.two-column-wrapper {
	&:first-child {
		margin-bottom: 40px;
		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
	}
}

.text-image.slim {
	@include std-bottom-margin(false);
	.employee-name + .job-title {
		margin-top: 10px;
	}
	.job-title + .copy {
		margin-top: 20px;
	}
	.copy + .copy {
		margin-top: 10px;
	}
	.copy:last-child {
		margin-bottom: 0;
	}
	.slim-subline {
		margin-top: 10px;
	}
	.copy-text {
		margin-top: 20px;
	}
	.img-wrapper {
		display: flex;
		position: relative;
		z-index: 1;
		margin: 0 auto;
		width: 160px;
		height: 160px;

		@include media-breakpoint-up(sm) {
			width: 100%;
			// sm-container: 540px
			height: calc(540px/12*4 - 30px);
		}
		@include media-breakpoint-up(md) {
			// md-container: 720px
			height: calc(720px/12*4 - 30px);
		}
		@include media-breakpoint-up(lg) {
			// lg-container: 960px
			height: calc(960px/12*3 - 30px);
		}
		@include media-breakpoint-up(xl) {
			// xl-container: 1310px
			height: calc(1310px/12*3 - 30px);
		}
	}
	.content-container {
		margin-top: -110px;
		z-index: 0;
		@include media-breakpoint-up(sm) {
			margin: -100px 0 0 auto;
		}
		@include media-breakpoint-up(md) {
			margin-top: -160px;
		}
		@include media-breakpoint-up(lg) {
			min-height: 210px;
		}
		@include media-breakpoint-up(xl) {
			margin-top: -238px;
			min-height: 298px;
		}
	}
	.content-wrapper {
		background-color: $color-grenke-grey-background;
		padding: 140px 30px 30px 30px;
		@include media-breakpoint-up(sm) {
			padding: 30px 30px 30px 15px;
		}
		@include media-breakpoint-up(md) {
			padding: 30px 30px 30px 0px;
		}
		@include media-breakpoint-up(lg) {
			padding: 40px 30px;
			height: 100%;
		}
		@include media-breakpoint-up(xl) {
			padding: 50px;
		}
		@include media-breakpoint-up(lg) {
			& > .row {
				min-height: 160px;
			}
		}
		@include media-breakpoint-up(xl) {
			& > .row {
				min-height: 198px;
			}
		}
		.btn-box {
			width: 100%;
		}
	}
}
