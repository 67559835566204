// styles for en_GB
#contact-detail-page {
    #contact-detail-form[data-language="en_GB"] {
        // show interests-values
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                display: block;
            }
        }
        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                // show topics
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Liquidity_Startups"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }

            &[data-form-topic="Leasing"] {
                .upload-row {
                    display: flex;
                }
            }
        }

        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                display: block;
                [data-dropdown-value="Dealer_Sales_Support"],
                [data-dropdown-value="Dealer_Advanced_Financing"] {
                    display: block;
                }
            }
        }


        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }
            .row-customer-service {
                display: flex;
            }
            [data-customer-service-item="CustomerNumber"],
            [data-customer-service-item="ContractNumber"] {
                display: block;
            }
        }

        .phone-contact-number-grenke-leasing {
            display: block;
        }
        #imprint-grenke-ag {
            display: inline;
        }

        // show press phone number
        &[data-form-topic="Liquidity_Entrepreneurs"],
        &[data-form-topic="Liquidity_Startups"],
        &[data-form-topic="Factoring"],
        &[data-form-topic="Dealer_Advanced_Financing"]
        {
            .phone-contact-number-grenke-leasing,
            #imprint-grenke-ag {
                display: none;
            }
            #imprint-grenke-factoring {
                display: block;
            }
            .phone-contact-number-grenke-factoring {
                display: block;
            }
        }
    }
}