// styles for es_ES MLA
#contact-detail-page {
    #contact-detail-form[data-language="es_ES"][data-campaign-name="mla"] {
        [data-form-input-name="VATNumber"] {
            display: block;
        }
        .time-slot {
            display: block;
        }
    }
}