.timeline {
    @include std-bottom-margin(false);
    .center-line {
        height: 100%;
        border-left: solid 1px $color-grenke-black;
        top: 0;
        left: 50%;
    }
    .timeline-item {
        & + .timeline-item {
            margin-top: 40px;
            @include media-breakpoint-up(sm) {
                margin-top: 60px;
            }
            @include media-breakpoint-up(lg) {
                margin-top: 90px;
            }
        }
        .image-container + .content-col {
            margin-top: 30px;
            @include media-breakpoint-up(lg) {
                margin-top: 50px;
            }
        }
        .image-container {
            @include media-breakpoint-up(md) {
                &:not(.mirrored) {
                    padding-right: 0;
                }
                &.mirrored {
                    padding-left: 1px;
                }
            }
            .image-wrapper {
                height: 190px;
                @include media-breakpoint-up(sm) {
                    height: 230px;
                }
                @include media-breakpoint-up(md) {
                    height: 250px;
                }
                @include media-breakpoint-up(lg) {
                    height: 335px;
                }
                @include media-breakpoint-up(xl) {
                    height: 440px;
                }
            }
        }
        .year-container {
            .timeline-line {
                top: 50%;
                width: 15px;
                border-bottom: solid 1px $color-grenke-black;
                &.mirrored {
                    right: -15px;
                }
                &:not(.mirrored) {
                    left: -15px;
                }
                @include media-breakpoint-up(lg) {
                    width: 20px;
                }
                @include media-breakpoint-up(xl) {
                    width: 30px;
                }
            }
        }
        .headline-container {
            margin-top: -35px;
            @include media-breakpoint-up(md) {
                margin-top: -40px;
            }
            @include media-breakpoint-up(xl) {
                margin-top: -50px;
            }
        }
        .headline-container + .copy {
            margin-top: 10px;
            @include media-breakpoint-up(md) {
                margin-top: 15px;
            }
        }
    }
}
