// styles for en_AU
#contact-detail-page {
  #contact-detail-form[data-language="en_AU"] {
    #requestInterest-col {
      // show en_AU interests
      [data-dropdown-value="Cooperation"],
      [data-dropdown-value="Consulting"],
      [data-dropdown-value="Offers"],
      [data-dropdown-value="Customer_Service"],
      [data-dropdown-value="Career"],
      [data-dropdown-value="Other"] {
        display: block;
      }
    }

    // show topic-dropdown
    &[data-form-interest="Cooperation"],
    &[data-form-interest="Consulting"],
    &[data-form-interest="Offers"],
    &[data-form-interest="Customer_Service"] {
      #requestTopic-col {
        display: block;
      }
    }

    // show topics
    &[data-form-interest="Consulting"] {
      #requestTopic-col {
        // show topics
        [data-dropdown-value="Liquidity_Entrepreneurs"],
          //[data-dropdown-value="Usage_Solutions"],
        [data-dropdown-value="Asset_Financing"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Cooperation"] {
      #requestTopic-col {
        // show topics
        //[data-dropdown-value="Dealer_Managed_Service"],
        [data-dropdown-value="Dealer_Sales_Support"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Offers"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing_And_Rental"] {
          display: block;
        }
      }

      .upload-row {
        display: flex;
      }

      [data-upload-title="supplier"], [data-upload-btn="supplier"] {
        display: block;
      }
    }

    &[data-form-interest="Customer_Service"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing"] {
          display: block;
        }
      }

      .row-customer-service {
        display: flex;
      }

      [data-customer-service-item="CustomerNumber"],
      [data-customer-service-item="ContractNumber"] {
        display: block;
      }
    }

    // show extra fields
    [data-form-input-name="VATNumber"],
    [data-form-input-name="Location"] {
      display: block;
      margin-top: 40px;
    }

    // show imprint-leasing
    .imprint-row,
    #imprint-grenke-leasing {
      display: block;
    }

    .phone-contact-number {
      // show phone-number-leasing
      &.phone-contact-number-grenke-leasing {
        display: block;
      }
    }

    // show timeslots
    #phone-time-wrapper-js {
      .time-slot {
        display: block;
      }
    }
  }
}
