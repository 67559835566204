.tiles-module {
    @include std-bottom-margin($extra: -30px);
    .tile-wrapper {
        width: 100%;
        height: 100%;
        align-items: center;
        @include media-breakpoint-up(sm) {
            padding: 0 15px;
        }
        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }
    .dot-bar {
        margin-top: 0px;
    }
    .tile {
        cursor: default;
        margin: 0 15px 15px 0;
        max-width: 260px;
        transition: box-shadow .5s;
        @include media-breakpoint-up(sm) {
            max-width: none;
        }
        .img-tile {
            display: block;
            width: 60px;
            height: 60px;
        }
        .slick-slide & {
            margin: 0;
        }
        &:hover {
            //box-shadow: 0 6px 6px 0 rgba(0,0,0,.26);
            box-shadow: 0 6px 6px 0 rgba(0,0,0,0.26), 0 10px 20px 0 rgba(0,0,0,0.19);
            transition: box-shadow .5s;
            // ToDo btn-style nur an einer Stelle
            .btn-tertiary.btn-light, .btn-tertiary-download.btn-light {
                left: 10px;
            }
        }
    }
    a {
        &:focus {
            outline: none;
        }
        .tile {
            cursor: pointer;
        }
    }
    .tile-gap {
        margin-bottom: 30px;
    }
}