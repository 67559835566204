.solution-finder-module {
    @include std-bottom-margin(false);
    .image-wrapper {
        width: 100%;
        height: 105px; // xs
        @include media-breakpoint-only(sm) {
            height: 160px;
        }
        @include media-breakpoint-only(md) {
            height: 215px;
        }
        @include media-breakpoint-only(lg) {
            height: 285px;
        }
        @include media-breakpoint-only(xl) {
            height: 400px;
        }
    }
    .heading-text {
        padding: 50px 10px 50px 40px;
        min-height: 315px;
        @include media-breakpoint-only(xl) {
            padding: 50px 20px 50px 50px;
        }
        &:after {
            content:"";
            position: absolute;
            right: -30px;
            top: 50%;
            width: 0;
            height: 0;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            border-left: 17px solid $color-grenke-white;
            margin-top: -17px;
            z-index: 1;
        }
    }
    .solution-finder-container {
        min-height: 315px;
        margin-top: -20px;
        @include media-breakpoint-only(sm) {
            margin-top: -30px;
        }
        @include media-breakpoint-only(md) {
            margin-top: -40px;
        }
        @include media-breakpoint-only(lg) {
            margin-top: -50px;
        }
        @include media-breakpoint-only(xl) {
            margin-top: -90px;
        }
    }
    .solution-finder-wrapper {
        background-color: $color-grenke-black;
        padding: 30px 30px 30px 30px;
        position: relative;
        width: calc( 100% + 1px );
        @include media-breakpoint-only(sm) {
            padding: 30px 30px 30px 30px;
        }
        @include media-breakpoint-only(md) {
            padding: 30px 60px 30px 60px;
        }
        @include media-breakpoint-only(lg) {
            padding: 30px 60px 30px 60px;
        }
        @include media-breakpoint-only(xl) {
            padding: 40px 80px 40px 80px;
        }
    }
}
