.table-module {
  width: 100%;
  position: relative;
  @include std-bottom-margin(false);
  .d-table {
    @include media-breakpoint-up(md) {
      table-layout: fixed;
    }
  }
  .table-module-container {
    overflow: auto;
    .table {
      margin-bottom: 0;
    }
    .d-table {
      width: 100%;
      min-width: 506px;
    }
    .body-row:nth-child(odd) {
      background-color: $color-grenke-grey-background;
    }
    .d-table-row {
      width: 100%;
    }
    .twentyfive {
      .d-table-cell {
        width: 25%;
      }
    }
    .twenty {
      .d-table-cell {
        width: 20%;
      }
    }
    .fifty {
      .d-table-cell {
        width: 50%;
      }
    }
    .thirtythree {
      .d-table-cell {
        width: 33.3%;
      }
    }
    .time-deposit {
      .d-table-cell {
        width: 20%;
      }
      .deposit-link {
        cursor: pointer;
        &:hover {
          p {
            text-decoration: underline;
          }
        }
      }
    }
    .one-three {
      .d-table-cell {
        width: 66.6%;
        &:first-child {
          width: 33.3%;
        }
      }
    }
    .body-row,
    .head-row {
      .d-table-cell {
        padding: 10px 15px;
        @include media-breakpoint-up(md) {
          padding: 10px 15px;
        }
        @include media-breakpoint-up(lg) {
          padding: 10px 20px;
        }
      }
    }

    .body-row:first-child .bordered {
      border-top: solid 1px $color-grenke-black;
    }
    .body-row:last-child .bordered {
      border-bottom: solid 1px $color-grenke-black;
    }

    .empty-cell {
      background-color: $color-grenke-white-light;
      padding: 0 0 0 15px !important;
      width: 15px !important;
      @media only screen and (min-width: 525px) {
        display: none !important;
      }
    }
  }
  .course-container {
    .gap-close {
      @include media-breakpoint-up(sm) {
        &-right {
          padding-right: 0;
        }
        &-left {
          padding-left: 0;
        }
      }
    }
    .course-headline {
      margin-bottom: 5px;
      @include media-breakpoint-up(lg) {
        padding-top: 10px;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
    .share-id {
      margin-bottom: 30px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 5px;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }
    .course-currency {
      margin-bottom: 10px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
      }
      .currency {
        margin-right: 10px;
      }
      .value {
        margin-right: 10px;
      }
      .arrow-wrapper {
        height: 17px;
        margin-left: 3px;
        .arrow {
          display: none;
          height: 100%;
          margin-left: 5px;
        }
      }
      .value-change-wrapper {
        .up {
          .arrow-up {
            display: block;
          }
        }
        .down {
          .arrow-down {
            display: block;
          }
        }
        .neutral {
          .arrow-neutral {
            display: block;
          }
        }
      }
    }
    .course-update-info {
      padding-bottom: 30px;
      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }
    }
  }
}
