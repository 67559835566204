// styles for pt_PT
#contact-detail-page {
  #contact-detail-form[data-language="pt_PT"] {
    // show interests-values
    #requestInterest-col {
      [data-dropdown-value="Consulting"],
      [data-dropdown-value="Offers"],
      [data-dropdown-value="Customer_Service"],
      [data-dropdown-value="Press"],
      [data-dropdown-value="Career"],
      [data-dropdown-value="Other"],
      [data-dropdown-value="Cooperation"] {
        display: block;
      }
    }

    // show topic-dropdown
    &[data-form-interest="Consulting"],
    &[data-form-interest="Offers"],
    &[data-form-interest="Customer_Service"],
    &[data-form-interest="Cooperation"] {
      #requestTopic-col {
        display: block;
      }
    }

    &[data-form-interest="Career"] {
      #optin-default {
        display: none;
      }
    }

    &[data-form-topic="Leasing_And_Rental"],
    &[data-form-topic="Leasing"],
    &[data-form-topic="Asset_Financing"],
    &[data-form-topic="Usage_Solutions"],
    &[data-form-interest="Press"],
    &[data-form-interest="Other"],
    &[data-form-interest="Career"],
    &[data-form-topic="Dealer_Managed_Service"],
    &[data-form-topic="Dealer_Sales_Support"] {
      #imprint-grenke-ag {
        display: block;
      }
    }

    &[data-form-topic="Factoring"],
    &[data-form-topic="Liquidity_Entrepreneurs"],
    &[data-form-topic="Liquidity_Startups"],
    &[data-form-topic="Dealer_Advanced_Financing"] {
      #imprint-grenke-factoring {
        display: block;
      }
    }

    [data-form-input-name="VATNumber"] {
      display: block;
      margin-top: 40px;
    }

    // show upload
    &[data-form-topic="Leasing_And_Rental"] {
      .upload-row {
        display: flex;
      }
    }

    // show upload supplier-headline and supplier-button
    //
    &[data-form-topic="Factoring"],
    &[data-form-topic="Leasing_And_Rental"] {
      [data-upload-title="supplier"], [data-upload-btn="supplier"] {
        display: block;
      }
    }

    &[data-form-interest="Consulting"] {
      #requestTopic-col {
        // show topics
        [data-dropdown-value="Liquidity_Entrepreneurs"],
        [data-dropdown-value="Usage_Solutions"],
        [data-dropdown-value="Asset_Financing"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Cooperation"] {
      #requestTopic-col {
        [data-dropdown-value="Dealer_Managed_Service"],
        [data-dropdown-value="Dealer_Sales_Support"],
        [data-dropdown-value="Dealer_Advanced_Financing"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Offers"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing_And_Rental"],
        [data-dropdown-value="Factoring"] {
          display: block;
        }
      }

      &[data-form-topic="Leasing"] {
        .upload-row {
          display: flex;
        }
      }
    }

    &[data-form-interest="Customer_Service"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing"],
        [data-dropdown-value="Factoring"] {
          display: block;
        }
      }

      .row-customer-service {
        display: flex;
      }

      [data-customer-service-item="CustomerNumber"],
      [data-customer-service-item="ContractNumber"] {
        display: block;
      }
    }

    &[data-form-interest="Career"] {
      [data-form-input-name="VATNumber"] {
        display: none;
      }
    }

    #phone-time-wrapper-js {
      .time-slot {
        display: block;
      }
    }

    // show initial phonenumbers
    .phone-contact-number {
      &.phone-contact-number-grenke-leasing {
        display: block;
      }
    }

    // show press phone number
    &[data-form-topic="Liquidity_Entrepreneurs"],
    &[data-form-topic="Liquidity_Startups"],
    &[data-form-topic="Factoring"],
    &[data-form-topic="Dealer_Advanced_Financing"] {
      .phone-contact-number-grenke-leasing {
        display: none;
      }

      .phone-contact-number-grenke-factoring {
        display: block;
      }
    }
  }
}
