.glossary-module {
  @include std-bottom-margin(false);

  .glossary-navigation-list-wrapper {
    padding-top: 12px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-grenke-grossary-border;
    @include media-breakpoint-up(sm) {
      padding-top: 12px;
      padding-bottom: 10px;
    }
    @include media-breakpoint-up(md) {
      padding-top: 22px;
      padding-bottom: 10px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 32px;
      padding-bottom: 20px;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 42px;
      padding-bottom: 50px;
    }
  }

  .glossary-navigation-list {
    margin-bottom: 0;
    padding: 0;

    .glossary-navigation-item {
      margin-top: 8px;

      a {
        &.disabled {
          opacity: 0.5;
          cursor: default !important;

          &:hover {
            box-shadow: none !important;
          }

          .glossary-navigation-text {
            &:hover {
              color: $color-grenke-black;
            }
          }
        }
      }

      .glossary-navigation-text {
        display: inline-block;
        color: $color-grenke-black;

        &:hover {
          color: $color-grenke-black;
        }
      }
    }
  }

  .glossary-content-container {
    margin-top: 30px;
    @include media-breakpoint-only(sm) {
      margin-top: 40px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 50px;
    }

    .glossary-content-headline {
      margin-bottom: 20px;
      @include media-breakpoint-only(sm) {
        margin-bottom: 10px;
      }
    }

    .glossary-content-table {
      div:has(> .glossary-content-table-headline):has(+ div .btn-box){
        display: flex;
        align-items: center;
        background: $color-grenke-grey-background;
        background-clip: content-box;

        .glossary-content-table-headline {
          flex: 1;
          height: auto;
        }
      }

      .glossary-content-table-border {
        border-top: 1px solid $color-grenke-black;

        &-close-line {
          border-top: 1px solid $color-grenke-black;
          margin-top: 20px;
          @include media-breakpoint-up(md) {
            margin-top: 0;
          }
        }
      }

      .glossary-content-table-headline {
        padding: 10px;
        background-color: $color-grenke-grey-background;
        height: 100%;
        color: $color-grenke-black;
        font-family: Helvetica-Regular;
        .info-bubble {
          display: inline-block;
          margin: 0 0 -8px 10px;
        }
      }

      .glossary-hidden-headline-extension {
        background-color: $color-grenke-grey-background;
        height: 100%;
        width: 100%;
      }

      .glossary-content-table-description {
        padding: 10px;
        @include media-breakpoint-up(md) {
          padding: 10px 0;
        }
      }

      .glossary-content-table-buttons {
        .btn-box {
          margin: 0 0 0 10px;
          @include media-breakpoint-up(md) {
            margin: 0;
          }
        }
      }

      & + .glossary-content-table {
        margin-top: 20px;
        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }
      .btn-tertiary-download {
        .btn-icon {
          img {
            height: 16px;
            width: 14px;
            margin-top: 1px;
          }
        }
      }
    }
  }
}
