// styles for en_GG
#contact-detail-page {
    #contact-detail-form[data-language="en_GG"] {
        // show interests-values
        #requestInterest-col {
            [data-dropdown-value="Investor_Relations"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show default number
        .phone-contact-number-grenke-ag {
            display: block;
        }

        &[data-form-interest="Investor_Relations"],
        &[data-form-interest="Press"],
        &[data-form-interest="Career"],
        &[data-form-interest="Other"] {
            #imprint-grenke-ag {
                display: inline;
            }
        }

        &[data-form-interest="Investor_Relations"] {
            [data-form-input-name="Company"],
            [data-form-input-name="PostalCode"] {
                display: none;
            }

            [data-form-input-name="Country"] {
                display: block;
            }

            .investor-relations-row {
                display: flex;
            }

            #optin-ir {
                display: block;
            }

            #optin-default {
                display: none;
            }
        }

        &[data-form-interest="Press"] {
            .phone-contact-number-grenke-ag-press {
                display: block;
            }
            .phone-contact-number-grenke-ag {
                display: none;
            }
        }
        &[data-form-interest="Career"] {
            .phone-contact-number-grenke-career {
                display: block;
            }
            .phone-contact-number-grenke-ag {
                display: none;
            }
        }

        #phone-time-wrapper-js {
            .time-slot {
                display: block;
            }
        }
    }
}