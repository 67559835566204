#leasing-calc {
  .leasing-calculator {
    select {
      @extend .formRow--input;
      @extend .formRow--dropdown-field;
      padding: 11px 17px;
      appearance: none;

      option {
        padding: 10px 15px;
        transition: background-color 0.5s;
        color: $color-grenke-black;
        background-color: #fff;

        &:hover {
          background-color: $color-grenke-white;
        }
      }

      &:disabled {
        background-color: $color-grenke-grey-light;
      }

      &.select-empty {
        color: $color-grenke-grey-dark-text;
      }
    }

    .arrow {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background-image: url(../website/medias/v2/icons/GRENKE_Icon_sign_arrow-down.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }

    input[type="text"] {
      transition: border-color 200ms ease-in-out;
      &.error {
        border-color: $color-grenke-error;
      }
      &:disabled {
        background-color: $color-grenke-grey-light;
      }
    }

    .formRow--dropdown-list {
      width: calc(100% - 0px);
    }

    #leasing-money-input {
      position: relative;

      &.right,
      &.left {
        &::after {
          position: absolute;
          top: 50%;
          color: $color-grenke-grey-light;
          font-size: 16px;
        }
      }

      &.right {
        text-align: left;
        padding-right: 50px;

        &::after {
          right: 10px;
        }
      }

      &.left {
        text-align: right;
        padding-left: 50px;

        &::after {
          left: 10px;
        }
      }

      &.EUR::after {
        content: "EUR";
      }

      &.CHF::after {
        content: "CHF";
      }

      &.GBP::after {
        content: "GBP";
      }
    }

    .input-end-label.left {
      right: unset;
      left: 0;
      transform: translateY(-50%) translateX(32px);
    }

    .result-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
    }

    button:disabled > span {
      background-color: $color-grenke-grey-light !important;
      border-color: $color-grenke-grey-light !important;
    }

    .over-value-dialog {
      opacity: 0;
      margin-left: 20px;
      transition: opacity 300ms ease-in-out;

      &.open {
        pointer-events: unset;
        opacity: 1;
      }
    }

    table.debug-table {
      th,
      td {
        padding: 10px 15px;
        color: $color-grenke-black;
        border: thin solid $color-grenke-grey-light;
        background-color: #fff;
        text-align: right;
      }

      tr:first-child th:first-child {
        background-color: transparent;
        border-top-color: transparent;
        border-left-color: transparent;
      }
    }
  }

  .leasing-form-container {
    max-height: 0;
    overflow-y: hidden;
    transition-property: height, max-height;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;

    &.open {
      max-height: 2000px;
    }
  }

  .leasing-dropdown {
    position: relative;

    &.disabled .placeholder .placeholder-underline {
      background-color: $color-grenke-grey-light;
    }

    .flip {
      border-top-color: $color-grenke-black;
      border-left-color: $color-grenke-black;
      border-right-color: $color-grenke-black;
    }

    .placeholder {
      position: absolute;
      top: 19px;
      left: 12px;
      display: block;
      margin: 0 5px;
      padding: 0;
      font-size: 16px;
      font-family: Helvetica;
      letter-spacing: 0;
      line-height: 16px;
      color: $color-grenke-grey-dark-text;
      white-space: nowrap;
      transition: all, 0.2s;
      transform: translateY(-50%);
      pointer-events: none;
      user-select: none;
      font-weight: 300;
      z-index: 5;
      transition-property: top, font-size, line-height, padding;
      transition-duration: 200ms;
      transition-timing-function: ease-in-out;

      &.has-value {
        @include formfield-copy;
        top: -15px;
        left: -9px;
        padding: 0 5px;
        color: $color-grenke-black;

        .placeholder-underline {
          opacity: 1;
        }
      }

      .placeholder-underline {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 2px;
        left: 0;
        opacity: 0;
        background-color: $color-grenke-white-light;
        z-index: -1;
      }
    }
  }
}
