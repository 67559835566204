p, address {
    margin: 0;
}

a {
  color: #000000;

  &:hover {
    color: #000000;
    text-decoration: none;
  }
}

// fix modal for ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .modal-open .modal {
        overflow-y: hidden;
    }
    .modal .modal-dialog {
        width: 100%;
    }
}
