.kpis-module {
    margin-bottom: 40px;
    @include media-breakpoint-up(sm) {
        margin-bottom: 50px;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 50px;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 80px;
    }

    .kpi-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .kpi-cards {
        flex: 100% 1 0;
    }

    .kpi-headline {
        border-bottom: 1px solid #000000;
    }

    .kpi-highlight {
        text-align: left;
        flex-shrink: 1;
        flex-grow: 1;
        align-self: center;

        &-value, &-left {
            font-size: 42px;
            font-weight: 800;
        }

        &-right {
            font-size: 28px;
            font-weight: 800;
        }

        & .diagram-highlight-description {
            font-size: 20px;
        }

        & .diagram-highlight-flexer {
            display: flex;
            align-items: center;
        }

        @include media-breakpoint-up(md) {
            &-value, &-left {
                font-size: 62px;
            }
            &-right {
                font-size: 40px;
            }
            & .diagram-highlight-description {
                font-size: 28px;
            }
        }

        &-value-flexer {
            display: flex;
            align-items: baseline;
            gap: 5px;
        }

        &-description-flexer {
            display: flex;
            align-items: center;
        }

        .kpi-trend-up {
            rotate: -45deg;
        }

        .kpi-trend-down {
            rotate: 45deg;
        }

        .kpi-trend-hidden {
            height: 48px;
            background-color: transparent;
            color: transparent;
        }
    }
}
