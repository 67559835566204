/* Fonts */
@font-face {
  font-family: "Helvetica-regular";
  src: url("../website/medias/fonts/HelveticaNeueLTW02-55Roman.eot");
  src: url("../website/medias/fonts/HelveticaNeueLTW02-55Roman.eot?#iefix") format("embedded-opentype"),
    url("../website/medias/fonts/HelveticaNeueLTW02-55Roman.woff2") format("woff2"),
    url("../website/medias/fonts/HelveticaNeueLTW02-55Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("../website/medias/fonts/HelveticaNeueLTW02-45Light.eot");
  src: url("../website/medias/fonts/HelveticaNeueLTW02-45Light.eot?#iefix") format("embedded-opentype"),
    url("../website/medias/fonts/HelveticaNeueLTW02-45Light.woff2") format("woff2"),
    url("../website/medias/fonts/HelveticaNeueLTW02-45Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeueLTW02-85Heavy";
  src: url("../website/medias/fonts/HelveticaNeueLTW02-85Heavy.eot?#iefix");
  src: url("../website/medias/fonts/HelveticaNeueLTW02-85Heavy.eot?#iefix") format("eot"),
    url("../website/medias/fonts/HelveticaNeueLTW02-85Heavy.woff2") format("woff2"),
    url("../website/medias/fonts/HelveticaNeueLTW02-85Heavy.woff") format("woff"),
    url("../website/medias/fonts/HelveticaNeueLTW02-85Heavy.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeueLTW02-86HvIt";
  src: url("../website/medias/fonts/HelveticaNeueLTW02-86HvIt.eot?#iefix");
  src: url("../website/medias/fonts/HelveticaNeueLTW02-86HvIt.eot?#iefix") format("eot"),
    url("../website/medias/fonts/HelveticaNeueLTW02-86HvIt.woff2") format("woff2"),
    url("../website/medias/fonts/HelveticaNeueLTW02-86HvIt.woff") format("woff"),
    url("../website/medias/fonts/HelveticaNeueLTW02-86HvIt.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Georgia";
  src: url("../website/medias/fonts/Georgia.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Georgia-bold";
  src: url("../website/medias/fonts/georgia bold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  &.hyphenopoly {
    -webkit-hyphens: none;
    -ms-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
  }

  &.hyphen-manual,
  .hyphen-manual & {
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    -moz-hyphens: manual;
    hyphens: manual;
  }
}

h1,
h2,
h3,
h4,
h5,
p,
a,
.copy-text,
.hero-text,
.copy,
.job-title {
  font-family: "Helvetica";
  font-weight: 300;
  font-style: normal;
}

p {
  font-size: inherit;
  line-height: inherit;

  .highlight-module .highlight-section & {
    color: $color-grenke-white;
  }

  .tiles-module .copy-text &,
  .tiles-module .copy & {
    color: $color-grenke-white;
  }

  .modal-content & {
    color: $color-grenke-black;
    font-size: 16px;
    line-height: 28px;
  }

  .comparison-chart-module & {
    font-family: "Helvetica-regular";
    text-align: center;
    color: $color-grenke-black;
  }

  .modal-content & {
    font-family: "Helvetica";
  }

  .table-module .time-deposit .deposit-link .copy-1 & {
    color: $color-grenke-black;
  }

  .timeline .year-container & {
    font-family: "Helvetica-regular";
    color: $color-grenke-grey-background;
    font-weight: 700;
    line-height: 80px;
    font-size: 80px;
    letter-spacing: 0;
    letter-spacing: -5px;
    @include media-breakpoint-up(sm) {
      line-height: 125px;
      font-size: 130px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 140px;
    }
    @include media-breakpoint-up(xl) {
      line-height: 200px;
      font-size: 200px;
    }
  }

  .footer-meta .country-switch:hover &.footer-item-link {
    font-family: "Helvetica-regular";
  }
}

/*overwrite specified for p tags used in the news teaser in the navbar flyout*/
.flyout-container .item-box.investor-relations,
.item-box.news-events .content-wrapper,
.news-teaser.flyout-news-teaser {
  p.navigation-news-headline.dotdotdot {
    color: $color-grenke-black;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0;
    max-height: 22px;
    font-weight: bold;
    @include media-breakpoint-up(sm) {
      font-size: 17px;
      line-height: 22px;
      max-height: 22px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 17px;
      line-height: 22px;
      max-height: 22px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 17px;
      line-height: 22px;
      max-height: 22px;
    }
  }
}

/* rte overwrite */
b,
strong {
  font-family: "Helvetica-regular";

  header & {
    font-weight: normal;
  }
}

/* rte overwrite */

h1 {
  color: $color-grenke-black;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0;

  &.headline {
    margin-bottom: 20px;

    .stage-module-new & {
      @include media-breakpoint-between(xs, sm) {
        margin-bottom: 10px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    font-size: 36px;
    line-height: 46px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 40px;
    line-height: 52px;
  }
}

h2 {
  color: $color-grenke-black;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0;
  @include media-breakpoint-up(sm) {
    font-size: 36px;
    line-height: 46px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 40px;
    line-height: 52px;
  }

  &.headline {
    margin-bottom: 20px;

    .stage-module-new & {
      @include media-breakpoint-between(xs, sm) {
        margin-bottom: 10px;
      }
    }
  }

  .tile & {
    color: $color-grenke-white;
    margin-bottom: 15px;
  }

  .headline-wrapper & {
    margin-bottom: 0;
  }

  .text-image.slim & {
    text-transform: none;
    @include media-breakpoint-up(xl) {
      margin-left: 26px;
    }
  }

  .article-overview & {
    &:hover {
      cursor: default;
    }
  }

  .stage-module & {
    &.headline {
      //-webkit-word-break: break-word;
      //-moz-word-break: break-word;
      //-ms-word-break: break-word;
      //word-break: break-word;
      //-webkit-hyphens: auto;
      //-moz-hyphens: auto;
      //-ms-hyphens: auto;
      //hyphens: auto;
    }
  }

  .suggestion-module & {
    text-transform: none;
  }

  &.errorpage {
    font-family: "Helvetica";
    font-size: 50px;
    line-height: 50px;
    text-align: center;
    color: $color-grenke-white;
    @include media-breakpoint-up(sm) {
      font-size: 75px;
      line-height: 75px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 102px;
      line-height: 102px;
    }
  }
  &.storytelling-small-headline {
    font-size: 24px;
    line-height: 34px;
    text-transform: none;

    @include media-breakpoint-up(sm) {
      font-size: 28px;
      line-height: 38px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 32px;
      line-height: 44px;
    }
  }
}

@mixin h3() {
  color: $color-grenke-black;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0;
  @include media-breakpoint-up(sm) {
    font-size: 28px;
    line-height: 38px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 32px;
    line-height: 44px;
  }
}

h3 {
  color: $color-grenke-black;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0;

  @include media-breakpoint-up(sm) {
    font-size: 28px;
    line-height: 38px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 32px;
    line-height: 44px;
  }
  .highlight-module & {
    text-transform: none;
  }

  .detached & {
    margin-bottom: 20px;
  }

  .text-image.box.default &,
  .text-image.box.mirrored & {
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }
  }

  .two-column & {
    margin-bottom: 15px;
  }

  .text-image.box.long & {
    margin-bottom: 10px;
  }

  .text-image.slim & {
    text-transform: none;
  }

  #contact-detail-form .contact-detail-form-interest-row & {
    color: $color-grenke-white;
  }

  #contact-content-page .stage-form-content-wrapper & {
    color: $color-grenke-white;
  }

  .notfound & {
    text-align: center;
    color: $color-grenke-white;
  }

  .tile & {
    color: $color-grenke-white;
    font-size: 17px;
    margin-bottom: 15px;
    word-break: normal;

    &.tiles-headline {
      line-height: 22px;
      letter-spacing: 0;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  &.process-headline {
    color: $color-grenke-black;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
      line-height: 28px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 28px;
      line-height: 38px;
    }
  }
}

h4 {
  color: $color-grenke-black;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  @include media-breakpoint-up(sm) {
    font-size: 21px;
    line-height: 28px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 28px;
    line-height: 38px;
  }

  .contact-module & {
    font-size: 28px;
    line-height: 38px;
  }

  .icon-module & {
    margin-bottom: 15px;
    padding: 0 15px;
    @include media-breakpoint-up(sm) {
      padding: 0 30px;
    }
  }

  .two-column & {
    margin-bottom: 15px;
  }

  .stage-form-container & {
    color: $color-grenke-white;
  }

  #contact-detail-form & {
    color: $color-grenke-black;
  }

  .modal-content & {
    width: 80%;
  }

  .comparison-chart-module & {
    text-align: center;
  }
}

h5 {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
    line-height: 26px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 23px;
    line-height: 32px;
  }

  .event-detail-info-box-location & + &,
  .event-detail-info-box-location & + .event-additional-location-info,
  .event-detail-info-box-location .event-additional-location-info + & {
    margin-top: 10px;
  }

  #newspage .subtitle & {
    color: $color-grenke-black;
  }

  .event-teaser-info &,
  .event-detail-subline-col & {
    color: $color-grenke-black;
  }

  .tile & {
    color: $color-grenke-white;
    margin-bottom: 15px;
    word-break: normal;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .table-module & {
    color: $color-grenke-black;
    margin-bottom: 5px;
    margin-top: -10px;
  }

  footer & {
    color: $color-grenke-black;
  }

  .event-detail-info-box-month-year & {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
  }

  .link-list.reports-downloads {
    .noHits {
      font-family: "Helvetica";
      color: $color-grenke-black;
    }
  }

  .link-list.reports-downloads & {
    color: $color-grenke-black;
  }

  .modal-content & {
    color: $color-grenke-black;
  }

  .teamwall.small & {
    color: $color-grenke-black;
  }
}

h6 {
  font-size: 17px;
  color: $color-grenke-black;
  letter-spacing: 0;
  line-height: 22px;
  font-family: "Helvetica-regular";

  .news-events &,
  .investor-relations & {
    color: $color-grenke-black;
    text-transform: none;
    font-family: "Helvetica";
  }
}

/*
header headlines format
 */
.header-navigation-headline {
  font-size: 17px;
  color: $color-grenke-black;
  letter-spacing: 0;
  line-height: 22px;
  font-family: "Helvetica-regular";
}

/*
footer headlines format
 */
.footer-contact-headline {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
    line-height: 26px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 23px;
    line-height: 32px;
  }
}

/*
icons headline format
 */
.icons-headline-small {
  color: $color-grenke-black;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  @include media-breakpoint-up(sm) {
    font-size: 21px;
    line-height: 28px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 28px;
    line-height: 38px;
  }
}

@mixin copy-1 {
  font-size: 14px;
  line-height: 23px;
  @include media-breakpoint-up(sm) {
    font-size: 15px;
    line-height: 26px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
    line-height: 28px;
  }
}

@mixin copy-3 {
  @include copy-1;
  & {
    font-family: "Helvetica-regular";
  }
}

p {
  letter-spacing: 0;

  &.employee-name {
    color: $color-grenke-black;
  }

  &.date-hero {
    color: $color-grenke-white;
    font-size: 36px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      font-size: 40px;
      line-height: 40px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 62px;
      line-height: 62px;
    }
  }

  &.date-sub {
    color: $color-grenke-white;
    font-size: 11px;
    font-weight: bold;
    line-height: 12px;
    text-align: center;
    margin-top: 5px;
    @include media-breakpoint-up(lg) {
      margin-top: 10px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 16px;
      line-height: 18px;
      margin-top: 15px;
    }
  }

  .text-image.slim & {
    @include media-breakpoint-up(xl) {
      margin-left: 26px;
    }
  }

  .link-list & {
    &.list-headline {
      color: $color-grenke-black;
      font-family: "Helvetica";
      letter-spacing: 0;
      font-size: 16px;
      line-height: 28px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
    }
  }

  .copy-1 &,
  &.copy-1,
  .copy-3 & {
    font-size: 14px;
    line-height: 23px;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
      line-height: 26px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  &.copy-1,
  .copy-1 & {
    .stage-form-container & {
      color: $color-grenke-white;
    }

    .glossary-content-table-description & {
      color: $color-grenke-black;
    }
  }

  .copy-3 & {
    font-family: "Helvetica-regular";
    color: $color-grenke-black;

    &.link-source {
      font-family: "Helvetica";
    }
  }

  .table-module .copy-1 & {
    color: $color-grenke-black;
  }

  .portfolio-module & {
    color: $color-grenke-black;
    font-family: "Helvetica";
    letter-spacing: 0;
    font-size: 14px;
    line-height: 23px;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
      line-height: 26px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .stage-module & {
    height: 100%;
  }

  .footer-navigation-headline {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 26px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 23px;
      line-height: 32px;
    }
  }

  footer & {
    color: $color-grenke-black;

    &.footer-navigation-headline,
    &.footer-newspaper-headline {
      font-size: 20px;
    }
  }

  &.sticky-headline,
  &.sticky-phone-description {
    font-size: 14px;
    line-height: 23px;
    font-family: "Helvetica";
    color: $color-grenke-black;
    text-align: center;
  }

  &.sticky-phone-number {
    font-size: 23px;
    line-height: 32px;
    color: $color-grenke-black;
    text-align: center;

    a {
      text-decoration: none;
      font-family: "Helvetica-regular";
    }
  }

  &.sticky-opening-hours {
    font-size: 10px;
    line-height: 15px;
    font-family: "Helvetica";
    color: $color-grenke-black;
    text-align: center;
  }

  &.green-color {
    color: $color-grenke-green-highlight;
  }

  &.red-color {
    color: $color-grenke-red;
  }

  &.grey-color {
    color: $color-grenke-black;
    opacity: 0.5;
  }

  .dashboard-group & {
    font-family: "Helvetica";

    &.green-color {
      font-family: "Helvetica-regular";
    }
  }

  .process-tile & {
    color: $color-grenke-black;
  }

  #countrySwitchModal & {
    font-family: "Helvetica";
    font-size: 15px;
    color: $color-grenke-black;
    letter-spacing: 0;
    line-height: 18px;

    &.country {
      color: $color-grenke-black;
    }

    &.column-headline-text {
      font-family: "Helvetica-regular";
    }
  }

  /* .headline-module & {
       margin-top: 10px;
       @include media-breakpoint-up(xl) {
           margin-top: 20px;
       }
   }*/

  /*
comparison chart headlines
 */
  &.comparison-chart-row-headline {
    display: block;
    text-align: center;
    color: $color-grenke-black;
    font-family: "Helvetica";
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    hyphens: auto;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
      line-height: 28px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  &.comparison-chart-column-headline {
    display: block;
    text-align: left;
    color: $color-grenke-black;
    font-family: "Helvetica";
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0;
    hyphens: auto;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 26px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 23px;
      line-height: 32px;
    }
  }
}

.icon-module-headline,
.icon-module-highlight {
  overflow: hidden;
  font-family: "Helvetica";
  font-size: 49px;
  line-height: 49px;
  letter-spacing: 0;
  color: $color-grenke-black;
  margin-bottom: 15px;
  font-weight: 300;
  @include media-breakpoint-up(lg) {
    font-size: 68px;
    line-height: 68px;
  }
}

@mixin tiles-module-copy() {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0;
}

.tiles-module-copy p {
  @include tiles-module-copy;
}

.copy {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0;
  color: $color-grenke-black;

  .text-image.box.long & + & {
    margin: 20px 0 0;
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  .highlight-module .headline + & {
    margin-top: 10px;
    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }
  }

  @include media-breakpoint-up(sm) {
    font-size: 15px;
    line-height: 26px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
    line-height: 28px;
  }

  .asset-slider-module h4 + & {
    margin-top: 15px;
  }

  .asset-slider-module .asset-indicator & {
    color: $color-grenke-black;
  }

  .inpage-navigation & {
    color: $color-grenke-grey-dark-text;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $color-grenke-black;
    }
    &:visited {
      text-decoration: none;
    }
  }

  &.accordion-copy {
    color: $color-grenke-black;
    font-family: "Helvetica-regular";
  }

  .explorer-module-small & {
    color: $color-grenke-white;
  }

  .interim-explorer-module-small & {
    color: $color-grenke-white;
  }

  .comparison-chart-module & {
    font-family: "Helvetica";
    text-align: center;
    margin: 30px 0 15px;
  }

  .process-flag & {
    line-height: 18px;
  }
}

.caption {
  color: $color-grenke-grey-dark-text;
  font-size: 13px;
  line-height: 18px;

  .footer-legal & {
    color: $color-grenke-black;
  }
}

.copy-3 {
  .inpage-navigation & {
    font-family: "Helvetica-regular";
    color: $color-grenke-black;
    font-size: 14px;
    line-height: 23px;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
      line-height: 26px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 16px;
      line-height: 28px;
    }

    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
}

.job-title,
.slim-subline {
  color: $color-grenke-black;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  @include media-breakpoint-up(sm) {
    font-size: 23px;
    line-height: 32px;
  }
}

.btn {
  font-family: "Helvetica-regular";
  font-weight: normal;
  font-style: normal;

  &.btn-tertiary,
  &.btn-tertiary-download,
  &.btn-tertiary-video-modal {
    font-family: "Helvetica";
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;

    .login-dealer & {
      font-family: "Helvetica-regular";
    }
  }
}

.hero-text {
  color: $color-grenke-black;
  letter-spacing: 0;

  &.small {
    font-size: 25px;
    line-height: 37px;
    @include media-breakpoint-up(sm) {
      font-size: 28px;
      line-height: 38px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 32px;
      line-height: 44px;
    }
  }

  &.medium {
    font-size: 25px;
    line-height: 37px;
    @include media-breakpoint-up(sm) {
      font-size: 28px;
      line-height: 38px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 40px;
      line-height: 55px;
    }
  }

  &.large {
    font-size: 25px;
    line-height: 37px;
    @include media-breakpoint-up(sm) {
      font-size: 40px;
      line-height: 52px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 55px;
      line-height: 65px;
    }
  }
}

@mixin a-text-link($color) {
  text-decoration: underline;
  color: $color;
  &:hover {
    color: $color;
  }
}

a {
  // default a text-link
  p & {
    color: $color-grenke-black;
    text-decoration: underline;

    &:hover {
      color: $color-grenke-black;
    }
  }

  span.text-wrap & {
    li & {
      color: $color-grenke-black;
      text-decoration: underline;

      &:hover {
        color: $color-grenke-black;
      }
    }
  }

  .contact-detail-form-interest-row p & {
    color: $color-grenke-white;
  }

  .stage-module .breadcrumb-container &,
  .stage-module-new .breadcrumb-container & {
    color: $color-grenke-grey-dark-text;

    &.active {
      color: $color-grenke-black;
    }

    &:hover {
      color: $color-grenke-black;
    }
  }

  &.navigation-list-item {
    font-size: 15px;
    font-weight: lighter;
  }

  .portfolio-module & {
    &:hover {
      text-decoration: none;
    }
  }

  .table-module & {
    color: $color-grenke-black;
    text-decoration: underline;
  }

  &:hover {
    .tiles-module & {
      text-decoration: none;
    }
  }

  footer & {
    color: $color-grenke-black;

    &:hover {
      color: $color-grenke-black;
    }
  }

  .language-select-container & {
    color: $color-grenke-black;

    &:hover {
      text-decoration: none;
    }
  }

  .news-teaser & {
    &:hover {
      text-decoration: none;
    }
  }

  .reports-downloads & {
    &:hover {
      text-decoration: none;
    }
  }

  .comparison-chart-module & {
    text-decoration: none;
  }

  .event-teaser-headline &,
  .event-teaser-subtitle & {
    color: $color-grenke-black;

    &:hover {
      text-decoration: none;
    }
  }

  #countrySwitchModal &:hover,
  #countrySwitchModal &.active {
    text-decoration: none;

    p {
      font-family: "Helvetica-regular";
    }
  }

  .teamwall.small &,
  .contact-module .content-wrapper .copy & {
    color: $color-grenke-black;
    word-break: break-word;

    &:hover {
      /* take style from contact-module  no variable */
      color: #0056b3;
    }
  }
}

.navigation-main span.navigation-list-item {
  font-size: 15px;
  font-weight: lighter;
  cursor: default;

  &:hover {
    color: $color-grenke-black;
  }
}

.small-print {
  font-family: "Helvetica";
  font-size: 13px;
  color: $color-grenke-white;
  letter-spacing: 0;
  text-align: center;
  line-height: 18px;

  .product-header-module & {
    color: $color-grenke-black;
    @include media-breakpoint-only(xs) {
      font-size: 12px;
    }
  }
}

.stage-module-adhoc {
  .adhoc-date {
    color: $color-grenke-white;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
      line-height: 28px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  .adhoc-headline {
    color: $color-grenke-white;
    text-align: left;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
      line-height: 28px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  .adhoc-source {
    font-family: "Helvetica-regular";
    text-align: center;
    color: $color-grenke-white;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 26px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 23px;
      line-height: 32px;
    }
  }
}

.meta-nav-item {
  font-size: 13px;

  a {
    font-size: 13px;
  }

  .mobile & {
    font-size: 13px;
  }
}

.employee-name {
  font-size: 28px;
  line-height: 38px;
}

/*headline are NOT h tags*/
.list-headline {
  color: $color-grenke-black;
  font-family: "Helvetica";
  letter-spacing: 0;
  font-size: 16px;
  line-height: 28px;
  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}

.portfolio-tile-headline {
  color: $color-grenke-white;
  font-family: "Helvetica";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  @include media-breakpoint-up(lg) {
    font-size: 13px;
    line-height: 18px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 20px;
    line-height: 28px;
  }
}

.eventdate-large {
  font-family: "Helvetica-Regular";
  font-size: 32px;
  color: $color-grenke-black;
  letter-spacing: 0;
  line-height: 44px;
}

.eventdate-small {
  font-family: "Helvetica-Regular";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 38px;
  margin-bottom: 10px;

  .event-teaser-module &,
  #event-page & {
    color: $color-grenke-black;
  }

  .event-teaser-module &.event-teaser-box-location {
    font-family: "Helvetica-regular";
  }
}

.eventlocation {
  font-family: "Helvetica";
  font-size: 23px;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 300;

  .event-teaser-module &,
  #event-page & {
    color: $color-grenke-black;
  }

  .event-teaser-module &.event-teaser-box-location {
    font-family: "Helvetica";
  }
}

/* end*/

.link-list {
  a:hover {
    text-decoration: none;
  }

  p {
    color: $color-grenke-black;
  }

  .download-link {
    font-family: "Helvetica";
    color: $color-grenke-black;
  }
}

.download-list {
  .headline {
    color: $color-grenke-black;
    font-family: "Helvetica";
    letter-spacing: 0;
    font-size: 16px;
    line-height: 28px;
    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }

  .download-item {
    &:hover {
      text-decoration: none;
    }

    p {
      color: $color-grenke-black;
    }

    .download-link {
      font-family: "Helvetica";
      color: $color-grenke-black;
    }
  }
}

.reports-downloads {
  .reports-reset-filter {
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    color: $color-grenke-grey-dark-text;
    text-decoration: underline;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }
}

.dashboard-ir {
  .small {
    font-family: "Helvetica";
    font-size: 13px;
    color: $color-grenke-black;
    letter-spacing: 0;
    line-height: 18px;
    @include media-breakpoint-up(xl) {
      line-height: 23px;
    }

    &.date {
      color: $color-grenke-date-gray;
    }

    &.info-source {
      line-height: 23px;
      text-align: center;
      @include media-breakpoint-up(xl) {
        line-height: 28px;
      }
    }
  }

  .chart-date {
    font-family: "Helvetica";
    font-size: 12px;
    color: $color-grenke-black;
    text-align: right;
    @include media-breakpoint-up(lg) {
      font-size: 13px;
    }
  }

  .bold {
    font-family: "Helvetica-regular";
    font-size: 14px;
    color: $color-grenke-black;
    letter-spacing: 0;
    line-height: 23px;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
      line-height: 26px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .holder-state {
    p {
      font-family: "Helvetica-regular";
      font-size: 16px;
      letter-spacing: 0;
      text-align: right;
      line-height: 28px;
    }
  }

  .currency {
    font-family: "Helvetica";
    font-size: 33px;
    color: $color-grenke-black;
    letter-spacing: 0.21px;
    line-height: 40px;
    @include media-breakpoint-up(sm) {
      line-height: 41px;
    }
    @include media-breakpoint-up(md) {
      line-height: 40px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 23px;
      letter-spacing: 0.15px;
      line-height: 26px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 32px;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  .value {
    font-family: "Helvetica";
    font-size: 60px;
    color: $color-grenke-black;
    line-height: 60px;
    @include media-breakpoint-only(lg) {
      font-size: 43px;
      line-height: 43px;
    }
  }

  .value-change-wrapper {
    .value-change {
      font-family: "Helvetica-regular";
      font-size: 17px;
      letter-spacing: 0;
      line-height: 29px;
      word-break: keep-all;
      @include media-breakpoint-only(sm) {
        line-height: 29px;
      }
      @include media-breakpoint-only(md) {
        line-height: 29px;
      }
      @include media-breakpoint-only(lg) {
        font-size: 14px;
        line-height: 21px;
      }
      @include media-breakpoint-only(xl) {
        line-height: 27px;
      }
    }

    .up {
      .value-change {
        color: $color-grenke-green-highlight;
      }
    }

    .down {
      .value-change {
        color: $color-grenke-red;
      }
    }

    .neutral {
      .value-change {
        color: $color-grenke-black;
      }
    }
  }

  .time-interval-tab-container {
    .time-interval-tab.active {
      .small {
        font-family: "Helvetica-regular";
      }
    }
  }
}

.table-module {
  .small {
    font-family: "Helvetica";
    font-size: 13px;
    color: $color-grenke-black;
    letter-spacing: 0;
    line-height: 20px;
    @include media-breakpoint-up(xl) {
      line-height: 28px;
    }
  }

  .currency {
    font-family: "Helvetica";
    font-size: 33px;
    color: $color-grenke-black;
    letter-spacing: 0.21px;
    line-height: 51px;
    @include media-breakpoint-up(md) {
      line-height: 52px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 23px;
      letter-spacing: 0.15px;
      line-height: 57px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 33px;
      letter-spacing: 0.21px;
      line-height: 52px;
    }
  }

  .value {
    font-family: "Helvetica";
    font-size: 68px;
    color: $color-grenke-black;
    line-height: 77px;
    letter-spacing: 0.44px;
    @include media-breakpoint-only(lg) {
      font-size: 50px;
      line-height: 77px;
      letter-spacing: 0.32px;
    }
  }

  .value-change-wrapper {
    .value-change {
      font-family: "Helvetica-regular";
      font-size: 17px;
      letter-spacing: 0;
      line-height: 28px;
      word-break: keep-all;
      @include media-breakpoint-up(sm) {
        line-height: 39px;
      }
      @include media-breakpoint-only(lg) {
        font-size: 14px;
        line-height: 51px;
      }
    }

    .up {
      .value-change {
        color: $color-grenke-green-highlight;
      }
    }

    .down {
      .value-change {
        color: $color-grenke-red;
      }
    }

    .neutral {
      .value-change {
        color: $color-grenke-black;
      }
    }
  }
}

.search-input,
.awesomplete ul li {
  font-family: "Helvetica-regular";
  letter-spacing: 0;
  font-size: 18px;
  line-height: 26px;
  @include media-breakpoint-up(sm) {
    font-size: 21px;
    line-height: 28px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 28px;
    line-height: 38px;
  }
  /* placeholders need to be styled separately, listing with ',' is not working(!) */
  &::-webkit-input-placeholder {
    color: $color-grenke-grey-dark-text;
    font-family: "Helvetica";
    letter-spacing: 0;
    font-size: 18px;
    line-height: 26px;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
      line-height: 28px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  &::-moz-placeholder {
    color: $color-grenke-grey-dark-text;
    font-family: "Helvetica";
    letter-spacing: 0;
    font-size: 18px;
    line-height: 26px;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
      line-height: 28px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  &:-moz-placeholder {
    color: $color-grenke-grey-dark-text;
    font-family: "Helvetica";
    letter-spacing: 0;
    font-size: 18px;
    line-height: 26px;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
      line-height: 28px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  &:-ms-input-placeholder {
    color: $color-grenke-grey-dark-text;
    font-family: "Helvetica";
    letter-spacing: 0;
    font-size: 18px;
    line-height: 26px;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
      line-height: 28px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
      line-height: 38px;
    }
  }
}

.search-input {
  color: $color-grenke-black;
}

.search-input::placeholder {
  color: $color-grenke-grey-dark-text;
}

.awesomplete ul {
  &:before {
    font-size: 15px;
    line-height: 25px;
    @include media-breakpoint-up(sm) {
      font-size: 17px;
    }
  }

  li {
    color: $color-grenke-black;
  }
}

.awesomplete-before {
  font-size: 15px;
  line-height: 25px;
  @include media-breakpoint-up(sm) {
    font-size: 17px;
  }
}

.corporate-facts-module {
  .fact-headline {
    font-family: "Helvetica";
    font-weight: 300;
    font-size: 60px;
    color: $color-grenke-black;
    letter-spacing: 0;
    line-height: 70px;
    @include media-breakpoint-up(lg) {
      font-size: 75px;
      line-height: 65px;
    }
  }

  .fact-text {
    font-family: "Helvetica";
    font-weight: 300;
    font-size: 17px;
    color: $color-grenke-black;
    letter-spacing: 0;
    line-height: 22px;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 26px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 23px;
      line-height: 32px;
    }
  }
}

.interim-explorer-module {
  .explorer-category {
    font-family: "Georgia-bold", serif, Arial;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    @include media-breakpoint-up(sm) {
      font-size: 16px;
      line-height: 24px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.5px;
    }
  }

  .explorer-subline {
    font-family: "Georgia-bold", serif, Arial;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 23px;
    @include media-breakpoint-up(sm) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .explorer-headline {
    font-family: "Georgia-bold", serif, Arial;
    font-weight: bold;
    color: $color-grenke-white-light;
    font-size: 28px;
    letter-spacing: 0.5px;
    line-height: 42px;
    text-align: center;
    @include media-breakpoint-up(sm) {
      font-size: 32px;
      line-height: 53px;
    }
    @include media-breakpoint-up(lg) {
      color: $color-grenke-white;
      font-size: 36px;
      line-height: 56px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 40px;
      line-height: 60px;
    }
  }
}

.interim-explorer-module-small {
  .explorer-category {
    font-family: "Georgia-bold", serif, Arial;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    color: $color-grenke-white;
    opacity: 0.5;
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 22px;
      @media only screen {
        font-size: 12px;
      }
    }
  }

  .explorer-headline {
    font-family: "Georgia-bold", serif, Arial;
    font-weight: bold;
    color: $color-grenke-white-light;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 28px;
    @media only screen and (max-width: 768px) and (orientation: landscape) {
      font-size: 15px;
      line-height: 21px;
    }
    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  .explorer-small-subline {
    @media only screen and (max-width: 768px) and (orientation: landscape) {
      font-size: 13px;
      line-height: 22px;
    }
  }
}

.interim-explorer-module-small {
  .explorer-category {
    &.sky {
      color: $color-grenke-explorer-sky-light;
    }

    &.aqua {
      color: $color-grenke-explorer-aqua-light;
    }

    &.mint {
      color: $color-grenke-explorer-mint-light;
    }

    &.olive {
      color: $color-grenke-explorer-olive-light;
    }

    &.lime {
      color: $color-grenke-explorer-lime-light;
    }

    &.curry {
      color: $color-grenke-explorer-curry-light;
    }

    &.orange {
      color: $color-grenke-explorer-orange-light;
    }

    &.rouge {
      color: $color-grenke-explorer-rouge-light;
    }
  }

  .explorer-headline {
    color: $color-grenke-white;
  }
}

.interim-explorer-module {
  .explorer-category,
  .explorer-subline,
  .explorer-headline {
    color: $color-grenke-white;
  }
}

@mixin meta-navi-flyout {
  font-family: "Helvetica";
  font-size: 17px;
  color: $color-grenke-black;
  letter-spacing: 0;
  line-height: 22px;
}

.customer-flyout-container,
.customer-mobile-login {
  .customer-section-headline {
    @include meta-navi-flyout;
  }
}

.meta-flyout-headline {
  @include meta-navi-flyout;
}

@mixin formfield-headline {
  font-family: Helvetica;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}

.formfield-headline {
  @include formfield-headline;
}

@mixin formfield-copy {
  font-family: Helvetica;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 300;
}

.formfield-copy {
  @include formfield-copy;
}

@mixin formfield-error {
  font-family: Helvetica;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  color: $color-grenke-red;
}

.formfield-error {
  @include formfield-error;
}

@mixin formfield-textarea {
  font-family: Helvetica;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.formfield-textarea {
  @include formfield-textarea;
}

.scroll-info {
  .scroll-wrapper {
    font-family: "Helvetica";
    color: $color-grenke-black;
    font-size: 12px;
    line-height: 14px;
  }
}

.search-category a {
  font-family: Helvetica;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0;
  color: $color-grenke-grey-dark-text;
  text-decoration: none;
}

.suggestion-module {
  .suggestion-mobile-header {
    color: $color-grenke-white;
  }

  .awesomplete ul li {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: $color-grenke-grey-dark-text;
    font-family: "Helvetica";

    mark {
      color: $color-grenke-grey-dark-text;
    }

    &:hover {
      color: $color-grenke-black;

      mark {
        color: $color-grenke-black;
      }
    }
  }
}

.table-module p.green-color {
  color: $color-grenke-green-highlight;
}

.table-module p.red-color {
  color: $color-grenke-red;
}

.event-additional-location-info {
  @extend h5;
}

#news-overview-container .content-wrapper .international,
.news-teaser .international-news,
.news-teaser .international,
.news-teaser .national-news,
.news-teaser .national {
  font-family: "Helvetica";
  font-size: 14px;
  color: $color-grenke-black-icon;
  letter-spacing: 0;
}

#news-overview-page .filter-container .formRow--checkbox-text {
  font-family: "Helvetica";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
}

.storytelling-quote {
  &-text-large,
  &-text-medium,
  &-text-small,
  &-text-extra-small {
    font-family: "Helvetica";
  }

  &-author {
    margin-top: 5px;
    font-family: "Helvetica";
    font-size: 9px;
    line-height: 14px;
    color: $color-grenke-black;
    @include media-breakpoint-only(md) {
      margin-top: 10px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 15px;
      margin-top: 15px;
    }
  }

  &-text-large {
    font-size: 23px;
    line-height: 31px;
    @include media-breakpoint-up(md) {
      font-size: 34px;
      line-height: 44px;
    }
    @include media-breakpoint-only(lg) {
      font-size: 44px;
      line-height: 56px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 56px;
      line-height: 70px;
    }
  }

  &-text-medium {
    font-size: 20px;
    line-height: 25px;
    @include media-breakpoint-up(md) {
      font-size: 27px;
      line-height: 39px;
    }
    @include media-breakpoint-only(lg) {
      font-size: 38px;
      line-height: 50px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 46px;
      line-height: 59px;
    }
  }

  &-text-small {
    font-size: 15px;
    line-height: 23px;
    @include media-breakpoint-up(md) {
      font-size: 21px;
      line-height: 32px;
    }
    @include media-breakpoint-only(lg) {
      font-size: 30px;
      line-height: 38px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 34px;
      line-height: 44px;
    }
  }

  &-text-extra-small {
    font-size: 13px;
    line-height: 18px;
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 24px;
    }
    @include media-breakpoint-only(lg) {
      font-size: 22px;
      line-height: 32px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 28px;
      line-height: 38px;
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-policy-title,
  h3,
  h4 {
    font-family: "Helvetica" !important;
    color: $color-grenke-black !important;
  }

  #ot-pc-desc,
  #onetrust-policy-text {
    a {
      font-family: "Helvetica" !important;
      font-weight: bold !important;
      color: $color-grenke-black !important;
    }
  }

  .ot-always-active {
    color: $color-grenke-black !important;
  }

  .ot-btn-container {
    .ot-pc-refuse-all-handler {
      color: $color-grenke-black !important;
      border-color: $color-grenke-black !important;
      background-color: #ffffff !important;
    }
  }
}

.no-tag-headline {
  font-size: 17px !important;
  color: $color-grenke-black;
  letter-spacing: 0;
  line-height: 22px !important;
}
