.interim-explorer-module {
  overflow: visible;
  @include std-bottom-margin(false);
  &.interim-explorer-image {
    margin-top: 40px;
    @include media-breakpoint-up(sm) {
      margin-top: 50px;
    }
  }
  .explorer-container {
    padding: 20px 0 30px 0;
    position: relative;
    transition: margin 2s;
    @include media-breakpoint-up(sm) {
      padding: 20px 0 50px 0;
      transition: margin 2s;
    }
    @include media-breakpoint-up(md) {
      transition: margin 2s;
    }
    @include media-breakpoint-up(lg) {
      padding: 60px 0;
      transition: margin 2s;
    }
    @include media-breakpoint-up(xl) {
      padding: 80px 0;
      transition: margin 2s;
    }
    &.animate {
      @include media-breakpoint-up(lg) {
        margin-right: 200px;
        margin-left: -200px;
      }
    }
    .explorer-teaser-image {
      position: absolute;
      top: 0;
      left: 30px;
      transform: translateY(-40px);
      transition: margin 2s;
      @include media-breakpoint-up(sm) {
        transform: translateY(-50px);
        transition: margin 2s;
      }
      @include media-breakpoint-up(md) {
        transition: margin 2s;
      }
      @include media-breakpoint-up(lg) {
        transition: margin 2s;
      }
      @include media-breakpoint-up(xl) {
        transition: margin 2s;
      }
      &.animate {
        margin-right: -200px;
        margin-left: 200px;
        @include media-breakpoint-up(lg) {
          margin-right: -400px;
          margin-left: 400px;
        }
      }
      img {
        width: 130px;
        @include media-breakpoint-up(sm) {
          width: 160px;
        }
        @include media-breakpoint-up(xl) {
          width: 190px;
        }
      }
    }
    .explorer-teaser-container {
      .explorer-headline-container {
        position: relative;
        overflow: visible;
        margin-bottom: 10px;
        .explorer-headline {
          color: $color-grenke-white;
          font-weight: bold;
          position: absolute;
          text-align: center;
          top: 50%;
          transform: translateY(-50%);
          max-width: 100%;
        }
        .explorer-headline-image {
          display: block;
          margin: 0 auto;
          width: 100%;
          max-width: 300px;
        }
      }
      .explorer-category-container {
        .explorer-category-text {
          color: $color-grenke-white;
          font-weight: bold;
        }
      }
    }
    .explorer-content-container {
      .explorer-text-container {
        margin-bottom: 10px;
        .explorer-text {
          color: $color-grenke-white;
        }
      }
      .explorer-subline-container {
        .explorer-subline {
          color: $color-grenke-white;
          font-weight: bold;
        }
      }
      .explorer-button-container {
        margin-top: 30px;
      }
    }
  }
}
.interim-explorer-module-small {
  .explorer-trigger {
    display: block;
    width: 1px;
    height: 1px;
  }
  .explorer-small-fixed-container {
    position: fixed;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    overflow: visible;
    z-index: 1000;
    .explorer-small-container-outer {
      width: 100%;
      height: 100%;
      position: relative;
      .explorer-small-container {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(100%, -50%);
        transition: transform 1s;
        width: 315px;
        @include media-breakpoint-up(md) {
          width: 362px;
        }
        .explorer-small-container-inner {
          padding: 10px 15px 20px 30px;
          min-height: 160px;
          position: relative;
          width: 100%;
          @include media-breakpoint-up(md) {
            padding: 10px 20px 20px 50px;
            min-height: 180px;
          }
          .explorer-small-image {
            z-index: -1;
            position: absolute;
            bottom: 0;
            right: 15px;
            width: 200px;
            max-width: 45%;

            img {
              width: 100%;
            }
          }
          .explorer-small-top-container {
            padding-right: 30px;
            margin-bottom: 10px;
            .explorer-small-close-button {
              width: 15px;
              cursor: pointer;
              margin-top: 5px;
              img {
                transform: scaleX(-1);
                width: 100%;
              }
            }
            .explorer-small-category {
              @include media-breakpoint-up(md) {
                margin-right: 5px;
              }
            }
          }
          .explorer-small-middle-container {
            width: 100%;
            margin-bottom: 10px;
            color: $color-grenke-white;
          }
          .explorer-small-bottom-container {
            width: 100%;
          }
        }
      }
      .explorer-small-collapsed-container {
        position: absolute;
        right: 0;
        top: 0;
        transition: right 0.5s;
        .ff-click-handler {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: transparent;
          opacity: 0;
          z-index: 1;
        }
        .explorer-small-expand-button {
          width: 55px;
          height: 55px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          img.explorer-small-collapsed-image {
            width: 150%;
            position: absolute;
            top: 0;
            right: 0;
          }
          img.icon {
            height: 16px;
          }
        }
      }
    }
  }
  &.collapsed,
  &.closing,
  &.closed,
  &.opening {
    .explorer-small-fixed-container {
      .explorer-small-container-outer {
        .explorer-small-container {
          transform: translate(0%, -50%);
        }
      }
    }
  }
  &.closing:not(.collapsed),
  &.opening:not(.collapsed) {
    .explorer-small-collapsed-container {
      display: block;
    }
  }
  &.closed:not(.collapsed) {
    .explorer-small-collapsed-container {
      display: block;
      right: -55px;
    }
  }
  &.edit-mode {
    .explorer-small-fixed-container {
      position: relative;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      overflow: visible;
      z-index: 1000;
    }
  }
}
.interim-explorer-module-small,
.interim-explorer-module {
  .explorer-small-container,
  .explorer-small-collapsed-container,
  &.interim-explorer-module,
  &.interim-explorer-module .btn {
    background-color: $color-grenke-explorer-sky;
  }
  &.sky {
    .explorer-small-container,
    .explorer-small-collapsed-container,
    &.interim-explorer-module,
    &.interim-explorer-module .btn {
      background-color: $color-grenke-explorer-sky;
    }
  }
  &.aqua {
    .explorer-small-container,
    .explorer-small-collapsed-container,
    &.interim-explorer-module,
    &.interim-explorer-module .btn {
      background-color: $color-grenke-explorer-aqua;
    }
  }
  &.mint {
    .explorer-small-container,
    .explorer-small-collapsed-container,
    &.interim-explorer-module,
    &.interim-explorer-module .btn {
      background-color: $color-grenke-explorer-mint;
    }
  }
  &.olive {
    .explorer-small-container,
    .explorer-small-collapsed-container,
    &.interim-explorer-module,
    &.interim-explorer-module .btn {
      background-color: $color-grenke-explorer-olive;
    }
  }
  &.lime {
    .explorer-small-container,
    .explorer-small-collapsed-container,
    &.interim-explorer-module,
    &.interim-explorer-module .btn {
      background-color: $color-grenke-explorer-lime;
    }
  }
  &.curry {
    .explorer-small-container,
    .explorer-small-collapsed-container,
    &.interim-explorer-module,
    &.interim-explorer-module .btn {
      background-color: $color-grenke-explorer-curry;
    }
  }
  &.orange {
    .explorer-small-container,
    .explorer-small-collapsed-container,
    &.interim-explorer-module,
    &.interim-explorer-module .btn {
      background-color: $color-grenke-explorer-orange;
    }
  }
  &.rouge {
    .explorer-small-container,
    .explorer-small-collapsed-container,
    &.interim-explorer-module,
    &.interim-explorer-module .btn {
      background-color: $color-grenke-explorer-rouge;
    }
  }
}
