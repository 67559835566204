.corporate-facts-module {
    @include std-bottom-margin(false);
    .image-container {
        position: absolute;
        top: 0;
        left: 0;
        .image-wrapper {
            .background-logo {
                height: 42px;
                @include media-breakpoint-up(lg) {
                    height: 59px;
                }
                @include media-breakpoint-up(xl) {
                    height: 79px;
                }
            }
        }
    }
    .flex-directionn-row {
        flex-direction: row;
    }
    .fact-text-behaviour {
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: left;
            &.mirrored {
                text-align: right;
            }
        }
    }
    .placeholder-height {
        &.top-container{
            @include media-breakpoint-up(md) {
                min-height: 332px;
            }
        }

        &.center-container{
            @include media-breakpoint-up(md) {
                min-height: 68px;
            }
        }

        &.bottom-container{
            @include media-breakpoint-up(md) {
                min-height: 222px;
            }
        }
    }
    .top-container {
        .border-item {
            border-top: solid 1px $color-grenke-black;
            border-right: solid 1px $color-grenke-black;
            height: 123px;
            margin-bottom: 40px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 60px;
            }
        }
        &.placeholder-height {
            @include media-breakpoint-up(md) {
                min-height: 332px;
            }
        }
    }
    .center-container {
        .fact-wrapper {
            @include media-breakpoint-up(md) {
                bottom: 50%;
                padding: 0 15px;
            }
        }
        .fact-border {
            @include media-breakpoint-up(md) {
                border-bottom: solid 1px $color-grenke-black;
            }
        }
    }
    .bottom-container {
        @include media-breakpoint-up(md) {
            margin-top: 40px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 60px;
        }
        .bottom-item {
            @include media-breakpoint-up(md) {
                height: 193px;
                border-bottom: solid 1px $color-grenke-black;
                &:not(.mirrored) {
                    border-right: solid 1px $color-grenke-black;
                    padding-right: 15px;
                }
                &.mirrored {
                    border-left: solid 1px $color-grenke-black;
                    padding-left: 15px;
                }
            }
            @include media-breakpoint-up(lg) {
                height: 204px;
            }
        }
    }
    .fact-headline {
        margin-bottom: 10px;
        @include media-breakpoint-up(md) {
            margin-bottom: 10px;
        }
    }
    .fact-text {
        margin-bottom: 40px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 50px;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 10px;
        }
    }
}
