footer {
    .back-to-top-container {
        padding-top: 0;
        padding-bottom: 40px;

      .back-to-top {
            caret-color: transparent !important;
            width: 48px;
            height: 48px;
            background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_black.svg");
            background-size: 24px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            border: 1px solid $color-grenke-black;
            @include transition(all, 0.5s);
            &:hover {
                box-shadow: -4px 4px 10px 0px #00000026;
            }
        }
    }
    .footer-item-link, .footer-copyright {
        font-size: 14px;
        line-height: 18px;
        color: $color-grenke-black;
    }
    .footer-contact {
        background-color: $color-grenke-footer;
        padding: 30px 0;
        @include media-breakpoint-up(lg) {
            padding: 40px 0;
        }
        .footer-contact-headline + .footer-contact-button {
            margin-top: 10px;
            @include media-breakpoint-up(sm) {
                margin-top: 30px;
            }
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }
    }
    .footer-social-media {
        padding-top: 20px;
        background-color: $color-grenke-white;
        .social-media-icon {
            padding: 0 25px;
            width: 38px;
            height: 38px;
            display: inline-block;
            margin: 20px 20px 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            @include media-breakpoint-up(sm) {
                padding: 0 40px;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .footer-social-media-spacer {
            padding-top: 40px;
            .spacer {
                width: 100%;
                height: 1px;
                background-color: $color-grenke-black;
            }
        }
    }
    .footer-newsletter {
        padding-top: 40px;
        background-color: $color-grenke-white;
        .input-col + .input-col {
            margin-top: 10px;
            @include media-breakpoint-up(sm) {
                margin-top: 0;
            }
        }
        .input-col + .email-col {
            margin-top: 10px;
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }
        .input-col + .submit-col {
            margin-top: 30px;
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }
        .footer-newsletter-spacer {
            padding-top: 40px;
            .spacer {
                width: 100%;
                height: 1px;
                background-color: $color-grenke-grey-light;
            }
        }
    }
    .footer-navigation {
        padding: 40px 0;
        background-color: $color-grenke-white;
        .footer-navigation-col {
            .footer-navigation-item {
                padding-top: 4px;
                padding-bottom: 4px;
            }
            &:nth-child(n+3) {
                margin-top: 60px;
                @include media-breakpoint-up(sm) {
                    margin-top: 50px;
                }
                @include media-breakpoint-up(lg) {
                    margin-top: 0px;
                }
            }
        }
        .footer-navigation-headline + .footer-navigation-item {
            margin-top: 3px;
        }
    }
    .footer-legal {
        background-color: $color-grenke-white;
        .footer-leagl-spacer {
            width: 100%;
            height: 1px;
            background-color: $color-grenke-black;
        }
        .footer-legal-text {
            padding: 40px 0;
        }
    }
    .footer-meta {
        padding-top: 30px;
        background-color: $color-grenke-white;
        .footer-meta-col-spacer + .footer-meta-col-spacer {
            margin-top: 25px;
            @include media-breakpoint-up(sm) {
                margin-top: 0;
            }
            &.campaign {
                @include media-breakpoint-only(sm) {
                    margin-top: 25px;
                }
            }
        }
        .footer-meta-col {
            padding: 0 10px;
        }
        .footer-meta-spacer {
            padding-top: 30px;
            margin-bottom: 25px;
            .spacer {
                width: 100%;
                height: 1px;
                background-color: $color-grenke-black;
            }
            @include media-breakpoint-up(md) {
                padding-top: 0;
                margin-bottom: 0;
                .spacer {
                    width: 1px;
                    height: 18px;
                }
            }
        }
        .footer-meta-copyright {
            margin-bottom: 25px;
        }
        .country-switch {
            cursor: pointer;
            margin-bottom: 30px;
            width: 100%;
            text-align: center;
            line-height: 1;
            @include media-breakpoint-up(sm) {
                margin-bottom: 40px;
            }
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                width: auto;
                text-align: left;
            }
            .flag {
                width: 20px;
                height: 15px;
                margin: 0 10px 1px 0;
            }
        }
        .meta-nav-wrapper {
            width: 100%;
            @include media-breakpoint-up(md) {
                width: auto;
            }
        }
    }
}
