.btn {
  box-sizing: border-box;
  white-space: pre-line;
  border-radius: 0;
  min-height: 48px;
  height: auto;
  width: 100%;
  display: flex;
  font-size: 12px;
  letter-spacing: 0.5px;
  padding: 0 30px;
  @include transition(box-shadow, 0.5s);
  &:active,
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .disabled & {
    opacity: 0.7;
    cursor: default !important;
    &:hover {
      box-shadow: none !important;
    }
  }

  &.btn-primary,
  &.btn-lock {
    padding: 4px 30px;
    font-family: "Helvetica-regular", Verdana;
    &.btn-light {
      @include button-color-primary(light);
    }
    &.btn-dark {
      @include button-color-primary(dark);
    }
    :is(&.btn-light, &.btn-dark) .primary {
      background-repeat: no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      rotate: 90deg;
    }
    &.btn-light .primary {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_black.svg");
    }
    &.btn-dark .primary {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_white.svg");
    }
    &.btn-transparent {
      @include button-color-primary(achromatic);
    }
    &.btn-dark .download {
      background: transparent url("../website/medias/v2/icons/GRENKE_Icon_digital_download_1_white.svg") center
        center/contain no-repeat;
    }
    &.btn-dark .lock {
      background: transparent url("../website/medias/v2/icons/GRENKE_Icon_sign_lock_1_white.svg") center center/contain
        no-repeat;
      width: 20px;
    }
    &.inactive {
      background-color: $color-grenke-grey-dark-70;
      color: $color-grenke-white;
      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
  }
  &.btn-secondary,
  &.btn-secondary-download {
    padding: 4px 30px;
    font-family: "Helvetica-regular", Verdana;
    @include transition(all, 0.5s);
    &.btn-light {
      @include button-color-secondary(light);
      &.no-text-hover {
        color: $color-grenke-white;
        &:hover,
        &:focus,
        &:active {
          color: $color-grenke-white;
        }
      }
    }
    &.btn-dark {
      @include button-color-secondary(dark);
    }
    :is(&.btn-light, &.btn-dark) :is(.secondary, .secondary-no-text-hover, .secondary-download) {
      background-repeat: no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      rotate: 90deg;
    }
    &.btn-light .secondary.hover-state {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_black.svg");
    }
    &.btn-light .secondary.no-hover-state {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_white.svg");
    }
    &.btn-dark .secondary {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_black.svg");
    }
    &.btn-light .secondary-no-text-hover {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_white.svg");
    }
    &.btn-dark .secondary-no-text-hover {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_black.svg");
    }
    &.btn-light .secondary-download.hover-state {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_black.svg");
    }
    &.btn-light .secondary-download.no-hover-state {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_white.svg");
    }
    &.btn-dark .secondary-download {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_black.svg");
    }
    &.btn-dark .upload {
      background: transparent url("../website/medias/v2/icons/GRENKE_Icon_digital-upload_1_black.svg") no-repeat center;
    }
    &.btn-dark .calendar {
      background: transparent url("../website/medias/v2/icons/GRENKE_Icon_sign_plus_1_black.svg") center center/contain
        no-repeat;
      width: 24px;
      height: 24px;
    }
    &.inactive {
      border: 1px solid $color-grenke-grey-dark-70;
      color: $color-grenke-grey-dark-text;
      box-shadow: none;
      background-color: transparent;
      &:hover,
      &:focus,
      &:active {
        border: 1px solid $color-grenke-grey-dark-70;
        background-color: transparent;
        color: $color-grenke-grey-dark-text;
        box-shadow: none;
      }
    }
  }
  &.btn-tertiary,
  &.btn-text-link,
  &.btn-tertiary-download,
  &.btn-tertiary-video-modal {
    padding: 0;
    height: auto;
    margin: 0;
    border: solid 2px transparent;
    header .mobile-wrapper & {
      border-left: none;
    }
    &.btn-light {
      @include button-color-tertiary(light);
    }
    &.btn-dark {
      @include button-color-tertiary(dark);
    }
    &.inactive {
      color: $color-grenke-grey-dark-text;
    }
    &.btn-navigation {
      color: $color-grenke-black;
      &:hover {
        color: $color-grenke-black;
        text-decoration: none;
      }
    }
    .portfolio-module & {
      min-height: 0;
    }
    .portfolio-tile &,
    .text-module & {
      margin: 10px 0 0;
    }
  }
  &.inactive {
    &:hover,
    &:focus,
    &:active {
      cursor: default;
    }
  }
  &.btn-text-link {
    min-height: 12px;
    margin-bottom: 15px;
    &:active,
    &:focus {
      outline: none;
    }
    &:first-child {
      margin-top: 10px;
    }
    .btn-menu-item-link & {
      margin-top: 0;
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      min-height: 40px;
      margin-bottom: 0px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  &.btn-secondary-download {
    .btn-caption {
      padding-left: 0;
      margin-left: -12px;
    }
    .btn-icon {
      margin-left: 10px;
    }
    img {
      width: 13px;
      height: 13px;
    }
  }

  &.btn-tertiary {
    .event-detail-info-box-location & {
      padding-top: 30px;
      min-height: 17px;
    }
  }
  &.btn-tertiary-video-modal {
    .btn-icon {
      margin-left: 0;
      img {
        width: 17px;
      }
    }
  }
  .stage-module-new & {
    height: 100%;
  }
  .icon-tertiary-video-modal {
    margin-top: 2px;
  }
  &.btn-tertiary,
  &.btn-custom-tertiary,
  &.btn-tertiary-nolink {
    .btn-icon {
      width: 30px;
      height: 30px;
    }
    :is(&.btn-light, &.btn-dark)
      :is(.tertiary, .custom-tertiary, .tertiary-nolink, .menu-overview, .tertiary-menu-teaser) {
      background-repeat: no-repeat;
      background-size: contain;
      width: 30px;
      height: 30px;
    }
    &.btn-light :is(.tertiary, .custom-tertiary, .tertiary-nolink) {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-right_1_white.svg");
    }
    &.btn-dark :is(.tertiary, .custom-tertiary, .tertiary-nolink, .menu-overview, .tertiary-menu-teaser) {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-right_1_black.svg");
    }
  }
  &.btn-tertiary-download,
  &.btn-tertiary-download-nolink,
  &.btn-tertiary-download-nolink-fallback {
    .btn-icon {
      width: 19px;
      height: 19px;
      margin-right: 5px;
    }
    :is(&.btn-light, &.btn-dark)
      :is(.tertiary-download, .tertiary-download-nolink, .tertiary-download-nolink-fallback) {
      background-repeat: no-repeat;
      background-size: contain;
      width: 19px;
      height: 19px;
    }
    &.btn-light :is(.tertiary-download, .tertiary-download-nolink, .tertiary-download-nolink-fallback) {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_digital_download_1_white.svg");
    }
    &.btn-dark :is(.tertiary-download, .tertiary-download-nolink, .tertiary-download-nolink-fallback) {
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_digital_download_1_black.svg");
    }
  }
  &.btn-tertiary-video-modal {
    .btn-icon {
      width: 17px;
      height: 17px;
    }
    &.btn-dark .tertiary-video-modal {
      background-repeat: no-repeat;
      background-size: contain;
      width: 17px;
      height: 17px;
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_digital_download_1_black.svg");
    }
  }

  .download {
    background: transparent url("../website/medias/v2/icons/GRENKE_Icon_digital_download_1_black.svg") center
      center/contain no-repeat;
    width: 20px;
  }
}

.btn-txt {
  display: flex;
  align-self: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  letter-spacing: inherit;
  &:active,
  &:focus {
    outline: none;
  }
  .btn-tertiary &,
  .btn-tertiary-download &,
  .btn-tertiary-video-modal & {
    text-align: left;
    margin: 0 auto 0 0;
    @include media-breakpoint-up(sm) {
      .stage-module-adhoc & {
        text-align: right;
        margin: 0 0 0 auto;
      }
    }
  }
  .btn-tertiary & {
    .stage-module-text & {
      text-align: right;
      margin: 0 0 0 auto;
      @include media-breakpoint-down(md) {
        margin: 0;
      }
    }
  }

  .btn-text-link & {
    text-align: left;
    margin: 0;
  }
}

.btn-icon:has(.download, .lock) {
  height: 20px;
  width: 20px;
}

.btn-icon {
  position: relative;
  display: flex;
  margin-left: 15px;
  .btn-tertiary &,
  .tertiary-video-modal & {
    margin-right: 10px;
    margin-left: 0;
  }
  .btn-tertiary-download & {
    margin-right: 0;
    margin-left: 0;
  }
  &.reverse {
    img {
      -webkit-transform: scale(-1) translateY(50%);
      -moz-transform: scale(-1) translateY(50%);
      -ms-transform: scale(-1) translateY(50%);
      -o-transform: scale(-1) translateY(50%);
      transform: scale(-1) translateY(50%);
    }
  }
  .download-icon {
    width: 13px;
    height: 13px;
  }
}

.btn-tertiary {
  .tile & {
    margin: 0;
    top: 0;
  }
}

.btn-box {
  &:active,
  &:focus {
    outline: none;
  }
  .stage-module & {
    width: 100%;
  }
  .contact-module & {
    width: 100%;
  }
  .highlight-module {
    width: 100%;
  }
  &:first-child {
    margin: 20px 0 30px 0;
  }
  .item-box-content & {
    &:first-child {
      margin: 20px 0 30px 0;
      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }
  }
  &:last-child {
    margin: 0;
  }
  .hightlight-module & {
    width: 100%;
  }
  .tile & {
    width: 100%;
    justify-self: start;
    align-self: flex-end;
  }
  &.btn-menu-item-link {
    margin: 0;
  }
  .text-image.slim & {
    margin-top: 30px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  .asset-slider-module & {
    margin: 15px 0 0;
    @include media-breakpoint-up(sm) {
      margin: 15px 0 0;
    }
    @include media-breakpoint-up(xl) {
      margin: 15px 0 0;
    }
  }
  .portfolio-module & {
    width: 100%;
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-self: end;
    align-self: flex-end;
  }
  .teamwall & {
    margin-top: 30px;
  }
  .text-image.two-column:not(.news-teaser) & {
    margin-left: 0;
  }
  #news-overview-page .text-image.two-column & {
    margin-left: 0;
  }
  // for equal height and width
  .loc-btn-wrapper & {
    width: 42px;
  }
}

.button-box-wrapper .btn-box {
  &:first-child {
    margin-bottom: 30px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.button-box .btn-box {
  &:first-child {
    margin-top: 0;
  }
}
.text-image.two-column {
  .button-col + .button-col {
    margin-top: 20px;
    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }
}

.btn-caption {
  .btn-text-link & {
    padding: 0;
    color: $color-grenke-black;
    &:active,
    &:focus {
      outline: none;
    }
    &:hover {
      font-family: "Helvetica-regular", Verdana;
    }
  }
}
.btn-main-menu,
.btn-multiple-numbers {
  .btn-caption {
    font-size: 17px;
    line-height: 22px;
  }
}
.button-align-right {
  .btn-tertiary {
    .btn-txt {
      margin: auto 0 auto auto;
    }
  }
}

.primary-flag + .tertiary-flag {
  .btn-box {
    align-self: center;
  }
}

.use-hyphens {
  hyphens: auto;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .stage-module-new & {
      word-break: normal;
      word-wrap: break-word;
      width: 100%;
    }
  }
}

.btn-close {
  caret-color: transparent !important;
  cursor: pointer;
  height: 24px;
  width: 24px;
  background: transparent url("../website/medias/v2/icons/GRENKE_Icon_sign_cross_line_1.svg") center no-repeat;
  right: 0;
  #countrySwitchModal & {
    top: 27px;
    left: -9px;
    @include media-breakpoint-up(md) {
      top: 16px;
    }
    @include media-breakpoint-up(lg) {
      top: 17px;
    }
  }
}

.btn-menu-item-link {
  padding: 5px 0;
  &:first-child {
    padding: 0 0 5px 0;
  }
  &:last-child {
    padding: 5px 0 0 0;
  }
  @include media-breakpoint-up(lg) {
    border-bottom: 1px solid $color-grenke-black;
    &:last-child {
      border: none;
    }
  }
  &.active .btn-text-link .btn-caption {
    font-family: "Helvetica-regular", Verdana;
    text-decoration: underline;
  }
}
.news-teaser .content-box:hover .btn {
  @include transition(left, 0.5s);
}
.flyout-container .teaser-box-item:hover .btn {
  @include transition(left, 0.5s);
}

.suggestion-btn {
  @include media-breakpoint-up(lg) {
    .btn-content {
      display: none;
    }
    .btn-icon {
      margin-left: 0;
      img {
        margin-left: -2px;
      }
    }
    .btn.btn-primary,
    .btn.btn-secondary {
      padding: 0;
      width: 48px;
      height: 48px;
    }
    .btn.btn-tertiary {
      margin-left: 5px;
    }
  }
}
