/*
	Styles for common elements
*/

.img-box {
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;

  .text-image & {
    height: 260px;
    left: auto;
    right: 0;
    @include media-breakpoint-up(sm) {
      height: 280px;
    }
    @include media-breakpoint-up(md) {
      height: 330px;
    }
    @include media-breakpoint-up(lg) {
      height: 440px;
    }
  }

  .text-image.detached & {
    padding: 0;
    height: 260px;
    @include media-breakpoint-up(sm) {
      padding: 0 15px;
      height: 280px;
    }
    @include media-breakpoint-up(lg) {
      padding-right: 30px;
      height: 365px;
    }
    @include media-breakpoint-up(xl) {
      height: 485px;
    }
  }

  .text-image.box & {
    padding: 0;
    height: 260px;
    @include media-breakpoint-up(sm) {
      padding: 0 15px;
      height: 280px;
    }
    @include media-breakpoint-up(md) {
      height: 330px;
    }
    @include media-breakpoint-up(lg) {
      height: 440px;
    }
    @include media-breakpoint-up(xl) {
      height: 590px;
    }
  }

  .text-image.two-column & {
    padding: 0;
    height: 220px;
    @include media-breakpoint-up(sm) {
      padding: 0 15px;
      height: 270px;
    }
    @include media-breakpoint-up(md) {
      height: 170px;
    }
    @include media-breakpoint-up(lg) {
      height: 235px;
    }
    @include media-breakpoint-up(xl) {
      height: 340px;
    }
  }

  .module-img {
    background-size: auto 100%;
    height: 100%;
    width: 100%;
  }

  .long & .example {
    background-size: 100%;
  }

  .two-column & .example {
    background: transparent url("../website/medias/dummy-images/box_2columns.jpg") left center no-repeat;
    height: 340px;
  }

  .icon-module & {
    margin-bottom: 40px;
    height: 90px;
    // ToDo width
    img {
      height: 90px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &[src$=".svg"] {
          width: 100%;
        }
      }
    }

    @include media-breakpoint-up(md) {
      height: 65px;
      img {
        height: 65px;
      }
    }
    @include media-breakpoint-up(lg) {
      height: 90px;
      img {
        height: 90px;
      }
    }
  }

  .contact & {
    height: 160px;
    width: 160px;
    z-index: 1;
    background-color: red;
  }
}

.content-box-wrapper {
  @include media-breakpoint-up(lg) {
    position: absolute;
    bottom: -30px;
  }
  @include media-breakpoint-up(xl) {
    bottom: -50px;
  }

  .default & {
    @include media-breakpoint-up(lg) {
      right: 0;
    }
  }

  .mirrored & {
    @include media-breakpoint-up(lg) {
      left: 0;
    }
  }

  .stage-module & {
    position: relative;
    bottom: auto;
  }
}

.content-box {
  position: relative;
  z-index: 1;

  .text-image.box.default & {
    background-color: $color-grenke-grey-background;
    margin-top: -50px;
    padding: 20px 30px 30px;
    @include media-breakpoint-up(sm) {
      padding: 30px;
      margin-top: -30px;
      right: 0;
    }
    @include media-breakpoint-up(md) {
      margin-top: -50px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 315px;
      max-height: 420px;
    }
    @include media-breakpoint-only(xl) {
      padding: 50px;
      max-height: 540px;
    }
  }

  .text-image.box.mirrored & {
    background-color: $color-grenke-grey-background;
    margin-top: -50px;
    padding: 20px 30px 30px;
    @include media-breakpoint-up(sm) {
      padding: 30px;
      margin-top: -30px;
    }
    @include media-breakpoint-up(md) {
      margin-top: -50px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 315px;
      max-height: 420px;
    }
    @include media-breakpoint-only(xl) {
      padding: 50px;
      max-height: 540px;
    }
  }

  .text-image.detached & {
    background-color: transparent;
  }

  .two-column & {
    display: flex;
    margin-top: -50px;
    @include media-breakpoint-up(sm) {
      margin-top: -80px;
    }
    @include media-breakpoint-up(md) {
      margin-top: -40px;
    }
  }

  &.tile {
    background-color: $color-grenke-black;
    color: $color-grenke-white;
    padding: 30px 30px 15px 30px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .tiles-slider & {
      width: 100%;
    }

    &.with-icon {
      padding: 10px 30px 15px 30px;
    }
  }

  .contact & {
    background-color: $color-grenke-white;
    margin-top: -110px;
    padding-top: 140px;
    z-index: 0;
  }
}

.copy-text {
  .mirrored &,
  .default & {
    margin-bottom: 30px;
  }

  .text-image.long & {
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 50px;
    }
  }

  .text-image.long.hero & {
    margin-bottom: 0;
  }

  .tile & {
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
  }

  .tile & p {
    overflow: hidden;
    text-align: left;
    width: 100%;
  }

  .icon-module & p {
    overflow: hidden;
    width: 100%;
    padding: 0 15px;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    @include media-breakpoint-up(sm) {
      padding: 0 30px;
    }
  }

  .stage-module & {
    height: 100%;
  }

  .search-result-box & {
    margin-bottom: 10px;
  }

  li a {
    color: $color-grenke-black;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}

.content-wrapper {
  .text-image.two-column & {
    @include media-breakpoint-up(md) {
      flex-direction: column;
    }
  }
}

.content-box-long {
  display: flex;
  position: relative;
  flex-direction: column;

  .text-image.box.long & {
    padding: 20px 30px 30px;
    background-color: $color-grenke-grey-background;
    @include media-breakpoint-up(sm) {
      padding: 30px;
    }
    @include media-breakpoint-up(lg) {
      padding: 40px;
    }
    @include media-breakpoint-up(xl) {
      padding: 50px;
    }
  }

  .text-image.box.long.hero & {
    background-color: transparent;
    padding: 0 15px;
  }

  @include media-breakpoint-up(sm) {
    padding: 30px;
  }
  @include media-breakpoint-up(lg) {
    padding: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding: 50px;
  }

  .quote-box {
    height: 40px;
    @include media-breakpoint-up(lg) {
      height: 60px;
    }
    @include media-breakpoint-up(xl) {
      height: 70px;
    }

    .quote-bg {
      background-color: $color-grenke-grey-background;
    }

    .quote {
      margin: 0 auto;
      height: 42px;
      background: transparent url("../website/medias/v2/assets/GRENKE_Asset_box-long-quotation-marks.svg") 99% 0
        no-repeat;
      background-size: cover;
      @include media-breakpoint-up(lg) {
        height: 62px;
      }
      @include media-breakpoint-up(xl) {
        height: 72px;
      }
    }
  }

  .text-image & {
    @include media-breakpoint-up(xs) {
      margin-top: -50px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -80px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: -100px;
    }
  }

  .text-box-left {
    .text {
      align-items: center;
      display: flex;
      padding: 25px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 15px 25px 50px;
    }
  }

  .text-box-right {
    padding-bottom: 30px;
    @include media-breakpoint-up(sm) {
      padding: 0 30px 30px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 50px 50px 15px;
    }
  }
}

.img-fill,
.img-fill-text-image {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.image-caption {
  .text-image.detached &,
  .text-image.box &,
  .stage-module-new &,
  .timeline &,
  .news-detail & {
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Helvetica";
    z-index: 3;
    color: $white;
    letter-spacing: 0;
    line-height: 14px;
    padding: 8px 15px 10px;
    font-size: 11px;
    width: 100%;
    z-index: 900;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 900;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }

    span {
      position: relative;
      z-index: 902;
    }

    @include media-breakpoint-up(sm) {
      font-size: 12px;
    }
    @include media-breakpoint-only(xl) {
      padding: 8px 10px 10px;
    }
  }

  .stage-module-new & {
    padding: 8px 0px 10px;

    &.product-active {
      top: 26px;
    }
  }
}

.button-box {
  .detached & {
    //margin-top: 30px;
    @include media-breakpoint-up(xl) {
      margin-top: 10px;
    }
    @include media-breakpoint-down(md) {
      margin-top: 0px;
    }
  }

  .contact & {
    padding-bottom: 15px;
  }

  .two-column & {
    padding: 30px 0 15px;
    @include media-breakpoint-up(sm) {
      width: 100%;
    }
    @include media-breakpoint-up(md) {
      margin: auto auto 0 0;
      width: 100%;
      align-self: flex-end;
    }
    @include media-breakpoint-up(lg) {
      width: 70%;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  .teaser-box-item & {
    margin: auto auto 0 0;
    width: 100%;
    align-self: flex-end;
  }

  .text-image.two-column & {
    @include media-breakpoint-up(lg) {
      width: 100%;
    }
    @include media-breakpoint-up(md) {
      margin: auto auto 0 0;
    }
  }

  .process-module & {
    padding: 0 15px;
    margin-top: 30px;
    @include media-breakpoint-only(md) {
      padding: 0;
    }
  }
}

.quote-border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid $color-grenke-white;
  border-right: 2px solid $color-grenke-white;
  border-left: 1px solid $color-grenke-white;
}

.btn-wrapper {
  .stage-module & {
    margin-top: 30px;
  }
}

.btn-spacer {
  .stage-module & + & {
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }
}

.flex-align-bottom-outer {
  display: flex;
  flex-direction: column;
}

.flex-align-bottom-inner {
  align-self: flex-end;
  margin: auto auto 0 0;
  width: 100%;
}

.grenke-bullets {
  @at-root ul#{&},
    & ul {
    list-style: none;
    list-style-position: outside;
    margin: 0;
    padding: 0;
    li {
      list-style-type: disc;
      margin-left: 15px;

      .text-wrap {
        width: 100%;

        a {
          /* These are technically the same, but use both */
          overflow-wrap: break-word;
          word-wrap: break-word;

          -ms-word-break: break-all;
          /* Instead use this non-standard one: */
          word-break: break-word;
        }
      }
    }
  }
  &.event-teaser-bullets,
  &.event-detail-description-bullets {
    li:nth-child(n + 2) {
      margin-top: 10px;
    }
  }
}

.inpage-navigation {
  height: 101px;
  justify-items: center;
  align-self: center;

  .inpage-navigation-item {
    padding: 0 25px;
  }

  .inpage-navigation-arrow {
    cursor: pointer;
  }
}

.infinite-scrolling-animation {
  margin: 20px auto 0;
  width: 70px;
  text-align: center;
  display: none;
  margin-top: 20px;

  &.active {
    margin-bottom: 120px;
    display: block;
    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 50px;
    }
  }

  .infinite-scrolling-animation-item {
    background-color: $color-grenke-black;
    display: inline-block;
    height: 18px;
    width: 18px;
    -webkit-animation: infinite-scrollling-animation-bouncedelay 1.4s infinite ease-in-out both;
    animation: infinite-scrollling-animation-bouncedelay 1.4s infinite ease-in-out both;

    &:first-child {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
}

@-webkit-keyframes infinite-scrollling-animation-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes infinite-scrollling-animation-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

#infinite-scrolling-back-to-top,
.infinite-scrolling-back-to-top-js {
  right: 0;
  height: 0px;
  overflow: visible;
  width: 100%;
  position: fixed;
  bottom: 0;
  opacity: 0;
  left: 0;
  z-index: 500;

  .infinite-scrolling-back-to-top-container {
    position: relative;
    height: 0;

    .back-to-top {
      position: absolute;
      caret-color: transparent !important;
      width: 48px;
      height: 48px;
      background-color: $color-grenke-white;
      background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_black.svg");
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      bottom: 40px;
      right: 15px;
      border: 1px solid #000;
      @include transition(all, 0.5s);

      &:hover {
        box-shadow: -4px 4px 10px 0px #00000026;
      }
    }
  }
}

/* only firefox */
@-moz-document url-prefix() {
  .grenke-bullets {
    @at-root ul#{&},
      & ul {
      li {
        &:before {
          @include media-breakpoint-only(xs) {
            background-position: 5px 10px;
          }
        }
      }
    }
  }
}

/* list edited by rtf*/
.copy-text {
  @at-root ul#{&},
    & ul {
    list-style: none;
    list-style-position: outside;
    margin: 0;
    padding: 0;

    li {
      list-style-type: disc;
      padding-left: 5px;
      margin-left: 15px;

      .tile & {
        color: $color-grenke-white;
      }

      .text-wrap {
        display: inline-block;
        padding-left: 20px;
      }
    }
  }
}
