// styles for de_DE
#contact-detail-page {
  #contact-detail-form[data-language="en_EN"] {
    // show interests-values
    #requestInterest-col {
      [data-dropdown-value="Cooperation"],
      [data-dropdown-value="Consulting"],
      [data-dropdown-value="Offers"],
      [data-dropdown-value="Customer_Service"],
      [data-dropdown-value="Press"],
      [data-dropdown-value="Career"],
      [data-dropdown-value="Other"] {
        display: block;
      }
    }

    // show topic-dropdown
    &[data-form-interest="Cooperation"],
    &[data-form-interest="Consulting"],
    &[data-form-interest="Offers"],
    &[data-form-interest="Customer_Service"] {
      #requestTopic-col {
        display: block;
      }
    }

    [data-form-input-name="TaxID"] {
      display: block;
      margin-top: 40px;
    }

    &[data-form-interest="Career"],
    &[data-form-interest="Other"],
    &[data-form-interest="Press"] {
      [data-form-input-name="TaxID"] {
        display: none;
      }
    }

    // show topic-values
    &[data-form-interest="Cooperation"] {
      #requestTopic-col {
        display: block;
      }

      #requestTopic-col {
        [data-dropdown-value="Dealer_Sales_Support"],
        [data-dropdown-value="Dealer_Managed_Service"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Consulting"] {
      #requestTopic-col {
        [data-dropdown-value="Liquidity_Entrepreneurs"],
        [data-dropdown-value="Usage_Solutions"],
        [data-dropdown-value="Asset_Financing"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Offers"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing_And_Rental"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Customer_Service"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing"] {
          display: block;
        }

        [data-customer-service-item="CustomerNumber"],
        [data-customer-service-item="ContractNumber"] {
          display: block;
        }
      }
    }

    // show default number
    .phone-contact-number-grenke-ag {
      display: block;
    }

    //
    // show imprint & phone GRENKE Factoring
    &[data-form-topic="Factoring"],
    &[data-form-topic="Liquidity_Entrepreneurs"] {
      #imprint-grenke-factoring,
      .phone-contact-number-grenke-factoring {
        display: block;
      }

      .phone-contact-number-grenke-ag {
        display: none;
      }
    }

    // show imprint & phone GRENKE AG
    &[data-form-interest="Investor_Relations"],
    &[data-form-interest="Press"],
    &[data-form-interest="Other"],
    &[data-form-interest="Career"] {
      #imprint-grenke-ag {
        display: block;
      }
    }

    // show GRENKE Business Solutions
    &[data-form-topic="Leasing"],
    &[data-form-topic="Leasing_And_Rental"],
    &[data-form-topic="Usage_Solutions"],
    &[data-form-topic="Asset_Financing"],
    &[data-form-topic="Individual"],
    &[data-form-topic="Dealer_Sales_Support"],
    &[data-form-topic="Dealer_Managed_Service"] {
      #imprint-grenke-business-solutions {
        display: block;
      }
    }

    // show press phone number
    &[data-form-interest="Press"] {
      .phone-contact-number-grenke-ag-press {
        display: block;
      }

      .phone-contact-number-grenke-ag {
        display: none;
      }
    }

    &[data-form-interest="Career"] {
      .phone-contact-number-grenke-career {
        display: block;
      }

      .phone-contact-number-grenke-ag {
        display: none;
      }
    }

    // show GRENKE BANK AG
    &[data-form-topic="Loans"],
    &[data-form-topic="Accounts"],
    &[data-form-topic="Investment"],
    &[data-form-topic="General"],
    &[data-form-topic="Banking"],
    &[data-form-topic="Banking_Services"],
    &[data-form-topic="Business_Banking"],
    &[data-form-topic="Startup_Financing"],
    &[data-form-topic="Liquidity_Startups"],
    &[data-form-topic="Dealer_Advanced_Financing"] {
      #imprint-grenke-bank-ag,
      .phone-contact-number-grenke-bank-ag {
        display: block;
      }

      .phone-contact-number-grenke-ag {
        display: none;
      }
    }

    // show upload
    &[data-form-topic="Loans"],
    &[data-form-topic="Accounts"],
    &[data-form-topic="Investment"],
    &[data-form-topic="Leasing_And_Rental"] {
      .upload-row {
        display: flex;
      }
    }

    // show upload bank-headline and bank-button
    //
    &[data-form-topic="Loans"],
    &[data-form-topic="Accounts"],
    &[data-form-topic="Investment"] {
      [data-upload-title="bank"], [data-upload-btn="bank"] {
        display: block;
      }
    }

    // show upload supplier-headline and supplier-button
    //
    &[data-form-topic="Leasing_And_Rental"] {
      [data-upload-title="supplier"], [data-upload-btn="supplier"] {
        display: block;
      }
    }

    &[data-form-interest="Customer_Service"][data-form-topic="Leasing"],
    &[data-form-interest="Customer_Service"][data-form-topic="Banking"] {
      .row-customer-service {
        display: flex;
      }
    }

    &[data-form-interest="Customer_Service"][data-form-topic="Leasing"] {
      [data-customer-service-item="CustomerNumber"],
      [data-customer-service-item="ContractNumber"] {
        display: block;
      }
    }

    &[data-form-interest="Customer_Service"][data-form-topic="Banking"] {
      [data-customer-service-item="AccountNumber"] {
        display: block;
      }
    }

    #phone-time-wrapper-js {
      .time-slot {
        display: block;
      }
    }

    &[data-form-interest="Customer_Service"][data-form-topic="Leasing"] {
      .phone-contact-number-customer-service-leasing {
        display: block;
      }

      .phone-contact-number-grenke-ag {
        display: none;
      }
    }

    [data-form-input-name="City"],
    [data-form-input-name="Location"],
    [data-form-input-name="VATNumber"] {
      display: block;
      margin-top: 40px;
    }
  }
}
