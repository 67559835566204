@mixin transition($selector, $time) {
  -webkit-transition: $selector $time;
  -moz-transition: $selector $time;
  -ms-transition: $selector $time;
  -o-transition: $selector $time;
  transition: $selector $time;
}

@mixin button-color-primary($color: light) {
  @if $color == "light" {
    background-color: $color-grenke-white;
    color: $color-grenke-black;
    &:hover {
      box-shadow: -4px 4px 10px 0px #00000059;
    }
    &:focus,
    &:active {
      box-shadow: -4px 4px 10px 0px #00000059;
      color: $color-grenke-black;
    }
  } @else if $color == "dark" {
    background-color: $color-grenke-black;
    color: $color-grenke-white;
    border: solid 1px $color-grenke-black;
    &:hover {
      box-shadow: -4px 4px 10px 0px #00000059;
      color: $color-grenke-white;
    }
    &:focus,
    &:active {
      box-shadow: -4px 4px 10px 0px #00000059;
      border: 2px solid $color-grenke-black;
      background-color: $color-grenke-black;
      color: $color-grenke-white;
    }
  } @else if $color == "achromatic" {
    //todo is primary?
    background-color: rgba(0, 0, 0, 0);
    color: $color-grenke-black;
    border: solid 1px $color-grenke-black;
    &:hover {
      box-shadow: -4px 4px 10px 0px #00000059;
    }
    &:focus,
    &:active {
      box-shadow: -4px 4px 10px 0px #00000059 !important;
      background-color: rgba(0, 0, 0, 0);
      color: $color-grenke-black;
      border: solid 2px $color-grenke-black;
      outline: none;
    }
  }
}

@mixin button-color-secondary($color: light) {
  @if $color == "dark" {
    background-color: transparent;
    border: 1px solid $color-grenke-black;
    color: $color-grenke-black;
    &:hover {
      box-shadow: -4px 4px 10px 0px #00000026;
      .btn-icon {
        .no-hover-state {
          opacity: 0;
        }
        .hover-state {
          opacity: 1;
        }
      }
    }
    &:focus,
    &:active {
      box-shadow: -4px 4px 10px 0px #00000026;
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      color: $color-grenke-black;
    }
  } @else if $color == "light" {
    background-color: $color-grenke-black;
    border: 1px solid $color-grenke-white;
    color: $color-grenke-white;
    .btn-icon {
      .no-hover-state {
        opacity: 1;
      }
      .hover-state {
        opacity: 0;
      }
    }
    &:hover {
      background-color: transparent;
      color: $color-grenke-black;
      box-shadow: -4px 4px 10px 0px #00000026;
      .btn-icon {
        .no-hover-state {
          opacity: 0;
        }
        .hover-state {
          opacity: 1;
        }
      }
    }
    &:focus,
    &:active {
      background-color: $color-grenke-white;
      color: $color-grenke-black;
      box-shadow: -4px 4px 10px 0px #00000026;
    }
  }
}

@mixin button-color-tertiary($color: light) {
  /* TODO hover is for testing */
  position: relative;
  left: 0;
  @include transition(left, 0.5s);

  @if $color == "light" {
    background-color: transparent;
    color: $color-grenke-white;
    border: none;
    &:hover {
      left: 10px;
    }
    &:active,
    &:focus,
    &:hover {
      background-color: transparent;
      color: $color-grenke-white;
      border: none;
      box-shadow: none;
    }
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      border: none;
      box-shadow: none;
    }
  } @else if $color == "dark" {
    background-color: transparent;
    color: $color-grenke-black;
    border: none;
    &:hover {
      left: 10px;
    }
    &:active,
    &:focus,
    &:hover {
      color: $color-grenke-black;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      border: none;
      box-shadow: none;
    }
  }
}

@mixin appearance($value: none) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin std-bottom-margin($isHeadline: false, $extra: 0px, $isTabTextImage: false) {
  @if $isHeadline == true {
    & {
      margin-bottom: calc(#{$bottom-gap-headline-module-xs} + #{$extra});
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: calc(#{$bottom-gap-headline-module-sm} + #{$extra});
    }
    @include media-breakpoint-up(md) {
      margin-bottom: calc(#{$bottom-gap-headline-module-md} + #{$extra});
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: calc(#{$bottom-gap-headline-module-lg} + #{$extra});
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: calc(#{$bottom-gap-headline-module-xl} + #{$extra});
    }
  } @else {
    & {
      margin-bottom: calc(#{$bottom-gap-module-xs} + #{$extra});
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: calc(#{$bottom-gap-module-sm} + #{$extra});
    }
    @include media-breakpoint-up(md) {
      margin-bottom: calc(#{$bottom-gap-module-md} + #{$extra});
    }
    @if $isTabTextImage == true {
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    } @else {
      @include media-breakpoint-up(lg) {
        margin-bottom: calc(#{$bottom-gap-module-lg} + #{$extra});
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: calc(#{$bottom-gap-module-xl} + #{$extra});
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .npl#{$infix}-only {
      padding-left: 0;
    }
    .npr#{$infix}-only {
      padding-right: 0;
    }
    .position#{$infix}-absolute-only {
      position: absolute;
    }
    .position#{$infix}-relative-only {
      position: relative;
    }

    .top#{$infix}-only-0 {
      top: 0;
    }

    .bottom#{$infix}-only-0 {
      bottom: 0;
    }

    .left#{$infix}-only-0 {
      left: 0;
    }

    .right#{$infix}-only-0 {
      right: 0;
    }

    .w#{$infix}-only-100 {
      width: 100%;
    }
  }
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .npl#{$infix} {
      padding-left: 0;
    }
    .npr#{$infix} {
      padding-right: 0;
    }
    .position#{$infix}-absolute {
      position: absolute;
    }
    .position#{$infix}-relative {
      position: relative;
    }

    .top#{$infix}-0 {
      top: 0;
    }

    .bottom#{$infix}-0 {
      bottom: 0;
    }

    .left#{$infix}-0 {
      left: 0;
    }

    .right#{$infix}-0 {
      right: 0;
    }

    .w#{$infix}-100 {
      width: 100%;
    }
  }
}

@mixin teamwall($end: 0) {
  @for $i from 1 through $end {
    &:nth-last-child(#{$i}) {
      margin-bottom: 0;
    }
  }
}

$sides: (top, bottom, left, right); // Leave this variable alone
@for $space from 1 through 100 {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
