// COLORS
// Primary Colors
$color-grenke-grey-background: #ececed;
$color-grenke-white: #ffffff;
$color-grenke-black: #000000;
$color-grenke-grey-dark-70: #9d9c97;
$color-grenke-grey-dark-text: #757575;
$color-grenke-white-light: #fcfcfb;
$color-grenke-white-80: rgba(255,255,255,.8);
$color-grenke-highlight: #f1efec;

// Specialties
$color-grenke-black-10: rgba(0,0,0,0.10);
$color-grenke-black-85: rgba(0,0,0,0.85);
$color-grenke-footer: $color-grenke-grey-background;
$color-grenke-grey-light: #d2d2d2;
$color-grenke-grey-dark-warm: #d5d2c7;
$color-grenke-black-icon: #000000;
$color-grenke-green-highlight: #aee084;
$color-grenke-buy: #605F5F;
$color-grenke-date-gray: #97948d;
$color-grenke-red: #a31919;
$color-grenke-error: #a31919;
$color-grenke-error-on-black: #F18383;
$color-grenke-country-switch-border: #d7d7d7;

// Explorer colours
$color-grenke-explorer-sky: #578696;
$color-grenke-explorer-sky-light: #81a1ad;
$color-grenke-explorer-aqua: #69979a;
$color-grenke-explorer-aqua-light: #90b0b0;
$color-grenke-explorer-mint: #699480;
$color-grenke-explorer-mint-light: #90ae9e;
$color-grenke-explorer-olive: #789764;
$color-grenke-explorer-olive-light: #9ab188;
$color-grenke-explorer-lime: #a6ab62;
$color-grenke-explorer-lime-light: #bec088;
$color-grenke-explorer-curry: #c59956;
$color-grenke-explorer-curry-light: #d4b37c;
$color-grenke-explorer-orange: #d48556;
$color-grenke-explorer-orange-light: #e1a67c;
$color-grenke-explorer-rouge: #b15e5b;
$color-grenke-explorer-rouge-light: #c6857e;

// Glossary colors
$color-grenke-grossary-border: $color-grenke-black;

$bottom-gap-headline-module-xl: 50px;
$bottom-gap-headline-module-lg: 40px;
$bottom-gap-headline-module-md: 30px;
$bottom-gap-headline-module-sm: 30px;
$bottom-gap-headline-module-xs: 20px;

$bottom-gap-module-xl: 130px;
$bottom-gap-module-lg: 100px;
$bottom-gap-module-md: 80px;
$bottom-gap-module-sm: 80px;
$bottom-gap-module-xs: 50px;

$top-gap-modul-reduce-xl: #{(-($bottom-gap-module-xl) + 80px)};
$top-gap-modul-reduce-lg: #{(-($bottom-gap-module-lg) + 60px)};
$top-gap-modul-reduce-md: #{(-($bottom-gap-module-md) + 50px)};
$top-gap-modul-reduce-sm: #{(-($bottom-gap-module-sm) + 50px)};
$top-gap-modul-reduce-xs: #{(-($bottom-gap-module-xs) + 50px)};

// z-index
// 1040, to be over all modules but under modals
$z-index-header: 1040;

$color-grenke-grey-background-body: #fcfcfb;
