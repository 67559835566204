// styles for nl_NL
#contact-detail-page {
  #contact-detail-form[data-language="nl_NL"] {
    // show interests-values
    #requestInterest-col {
      [data-dropdown-value="Consulting"],
      [data-dropdown-value="Offers"],
      [data-dropdown-value="Customer_Service"],
      [data-dropdown-value="Press"],
      [data-dropdown-value="Career"],
      [data-dropdown-value="Other"],
      [data-dropdown-value="Cooperation"] {
        display: block;
      }
    }

    // show topic-dropdown
    &[data-form-interest="Consulting"],
    &[data-form-interest="Offers"],
    &[data-form-interest="Customer_Service"],
    &[data-form-interest="Cooperation"] {
      #requestTopic-col {
        display: block;
      }
    }

    &[data-form-interest="Consulting"] {
      #requestTopic-col {
        [data-dropdown-value="Liquidity_Entrepreneurs"],
        [data-dropdown-value="Usage_Solutions"],
        [data-dropdown-value="Asset_Financing"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Cooperation"] {
      #requestTopic-col {
        [data-dropdown-value="Dealer_Managed_Service"],
        [data-dropdown-value="Dealer_Sales_Support"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Offers"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing_And_Rental"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Customer_Service"] {
      #requestTopic-col {
        [data-dropdown-value="Leasing"] {
          display: block;
        }
      }

      &[data-form-topic="Leasing"] {
        .row-customer-service {
          display: flex;
        }

        [data-customer-service-item="CustomerNumber"],
        [data-customer-service-item="ContractNumber"] {
          display: block;
        }

        .phone-contact-number-grenke-leasing {
          display: none;
        }

        #imprint-grenke-ag, .imprint-row, .phone-contact-number-customer-service-leasing {
          display: block;
        }
      }
    }

    #imprint-grenke-ag, .imprint-row, .phone-contact-number-grenke-leasing {
      display: block;
    }

    &[data-form-interest="Career"] {
      .phone-contact-number {
        display: none;
      }

      .phone-contact-number-grenke-career {
        display: block;
      }
    }

    #phone-time-wrapper-js {
      .time-slot {
        display: block;
      }
    }

    [data-form-input-name="CompanyRegistrationNo"] {
      display: block;
      margin-top: 40px;
    }

    &[data-form-topic="Leasing_And_Rental"] {
      .upload-row {
        display: flex;
      }

      [data-upload-title="supplier"], [data-upload-btn="supplier"] {
        display: block;
      }
    }
  }
}
