// styles for en_IE
#contact-detail-page {

    #contact-detail-form[data-language="en_IE"] {
        .row-customer-service {
            display: none;
        }
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                display: block;
            }
        }

        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Asset_Financing"],
                [data-dropdown-value="Liquidity_Public"],
                [data-dropdown-value="Liquidity_Startups"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                [data-dropdown-value="Dealer_Sales_Support"],
                [data-dropdown-value="Dealer_Advanced_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }
        }

        &[data-form-topic="Asset_Financing"],
        &[data-form-topic="Leasing_And_Rental"],
        &[data-form-topic="Dealer_Sales_Support"],
        &[data-form-topic="Dealer_Advanced_Financing"],
        &[data-form-topic="Liquidity_Entrepreneurs"],
        &[data-form-topic="Liquidity_Public"],
        &[data-form-topic="Leasing"],
        &[data-form-interest="Career"],
        &[data-form-interest="Press"],
        &[data-form-interest="Other"] {
            #imprint-grenke-leasing {
                display: block;
            }
        }

        &[data-form-topic="Factoring"],
        &[data-form-topic="Liquidity_Startups"] {
            #imprint-grenke-leasing {
                display: none;
            }
            #imprint-grenke-factoring {
                display: block;
            }
        }

        // display contact-number
        .phone-contact-number-grenke-leasing {
            display: block;
        }
        &[data-form-topic="Factoring"],
        &[data-form-topic="Liquidity_Startups"] {
            .phone-contact-number-grenke-leasing {
                display: none;
            }
            .phone-contact-number-grenke-factoring {
                display: block;
            }
        }

        // display Upload
        &[data-form-topic="Leasing_And_Rental"] {
            .upload-row {
                display: flex;
            }
            [data-upload-title="supplier"], [data-upload-btn="supplier"] {
                display: block;
            }
        }
    }
}