 .accordion {
     .container + .container-fluid {
         .accordion-body {
             padding-top: 30px;
             @include media-breakpoint-up(md) {
                 padding-top: 40px;
             }
         }
     }
     &.reduce-top {
         margin-top: $top-gap-modul-reduce-xs;
         @include media-breakpoint-up(sm) {
             margin-top: $top-gap-modul-reduce-sm;
         }
         @include media-breakpoint-up(md) {
             margin-top: $top-gap-modul-reduce-md;
         }
         @include media-breakpoint-up(lg) {
             margin-top: $top-gap-modul-reduce-lg;
         }
         @include media-breakpoint-up(xl) {
             margin-top: $top-gap-modul-reduce-xl;
         }
     }
     .accordion-cursor {
        cursor: pointer;
         .accordion-wrapper {
             border-top: solid 1px $color-grenke-black;
             .accordion-head {
                 padding: 20px 5px;
                 transition: background-color .5s;
                 .arrow {
                     min-width: 24px;
                 }
                 &:not(.collapsed) {
                     background-color: $color-grenke-grey-background;
                     .arrow {
                         transform: rotateX(180deg);
                     }
                 }
                 @include media-breakpoint-up(sm) {
                     padding: 20px 10px;
                 }
                 @include media-breakpoint-up(md) {
                     padding: 20px 20px;
                 }
                 @include media-breakpoint-up(lg) {
                     padding: 20px 30px;
                 }
                 @include media-breakpoint-up(xl) {
                     padding: 20px 40px;
                 }
                 .text-distance {
                     text-align: center;
                     margin-right: 10px;
                 }
             }
             .accordion-body {
                 padding: 30px 0 0 0;
                 @include media-breakpoint-up(md) {
                     padding: 40px 0 0 0;
                 }
                 @include media-breakpoint-up(xl) {
                     padding: 50px 0 0 0;
                 }
             }
         }
     }
     &.accordion-last {
         @include std-bottom-margin(false);
         .accordion-wrapper {
             border-bottom: solid 2px $color-grenke-grey-light;
         }
     }
     .text-module {
         margin-bottom: 30px;
         @include media-breakpoint-up(md) {
             margin-bottom: 40px;
         }
         @include media-breakpoint-up(xl) {
             margin-bottom: 50px;
         }
     }
 }

 .top-minus {
     margin-top: -2px;
 }
