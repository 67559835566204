.notfound-wrapper {
  padding-bottom: 108px;
  min-height: 840px;
  background: url("../website/medias/images/bg_bild.jpg") no-repeat center;
  background-size: cover;
  margin-bottom: -95px;
  @include media-breakpoint-up(sm) {
    padding-bottom: 78px;
  }
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 100px);
    padding-bottom: 100px;
  }
  @include media-breakpoint-up(xl) {
    height: calc(100vh - 100px);
    padding-bottom: 100px;
  }
  .notfound {
    margin-bottom: 50px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 70px;
    }
    .errorpage {
      margin: 100px 0 15px;
      @include media-breakpoint-up(sm) {
        margin: 120px 0 15px;
      }
      @include media-breakpoint-up(lg) {
        margin: 140px 0 15px;
      }
      @include media-breakpoint-up(xl) {
        margin: 160px 0 15px;
      }
    }
  }
}
