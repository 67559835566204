.process-module {
    @include std-bottom-margin(false);
}

.process-module {
    .process-flag {
        position: relative;
        background-color: $color-grenke-white;
        padding: 10px;
        width: auto;
        margin: 0 auto 30px;
        text-align: center;
        @include media-breakpoint-up(lg) {
            margin: 0 auto 40px;
        }
        &:after {
            background-color: $color-grenke-white;
            bottom: -10px;
            content: '';
            position: absolute;
            left: 50%;
            width: 11px;
            height: 11px;
            transform: rotate(45deg) translate(-50%, 0);
        }
    }
    .process-content {
        text-align: center;
        position: relative;
        @include media-breakpoint-up(md) {
            min-height: 286px;
        }
        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            background: url("../website/medias/v2/icons/GRENKE_Icon_sign_processline-right_1_black.svg") no-repeat center;
            background-size: contain;
            width: 32px;
            height: 100%;
            right: -24px;
        }
        @include media-breakpoint-up(md) {
             padding: 0 10px;
        }
        @include media-breakpoint-up(xl) {
            padding: 0 15px;
        }
        .img-box {
            height: 60px;
            width: 60px;
            margin: 0 auto 20px;
            @include media-breakpoint-up(sm) {
                margin: 0 auto 30px;
            }
            @include media-breakpoint-up(md) {
                height: 80px;
                width: 80px;
                margin: 0 auto 30px;
            }
            @include media-breakpoint-up(lg) {
                height: 90px;
                width: 90px;
                margin: 0 auto 40px;
            }
            img {
                height: 60px;

                @include media-breakpoint-up(md) {
                    height: 80px;
                }
                @include media-breakpoint-up(lg) {
                    height: 90px;
                }
            }
        }
        h4 {
            margin-bottom: 10px;
        }
    }
    .process-tile {
        margin-bottom: 45px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 60px;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
            .process-content {
                &:after {
                    display: none;
                }
            }
        }
    }
    .mobile {
        .process-content {
            @include media-breakpoint-down(sm) {
              &:after {
                background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_processline-down_1_black.svg");
                width: 100%;
                height: 32px;
                left: 0;
                top: 120%;
              }
            }
        }
        .process-tile {
            position: relative;
            margin-bottom: 91px;
            @include media-breakpoint-up(sm) {
                margin-bottom: 104px;
            }

            &:last-child {
                margin-bottom: 0;
                &:after {
                    background: none;
                }
            }
        }
    }
}
