
.slick-slide {
    outline: none;
    height: auto;
    .tiles-slider & {
        display: flex;
        padding: 0 0 20px 0px;
        & > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-items: center;
        }
        .tile-wrapper {
            transition: transform .4s;
            transform: scale(.9);
        }
        &.slick-current, &.slick-center, &.next-active {
            .tile-wrapper {
                transform: scale(1);
            }
        }
    }
    .stage-slider .content-slider & {
        display: flex;
        height: auto;
        & > div {
            width: 100%;
        }
    }
}

.slick-track {
    .tiles-slider &, .stage-slider & {
        display: flex;
    }
    .stage-slider .content-slider & {
        background-color: $color-grenke-white;
    }
}

.slick-dots {
    display: none !important;
}

.dot-bar{
    margin-top: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    height: 12px;
    justify-content: center;
    align-content: center;
    caret-color: transparent !important;
    .icons-box & {
        margin-top: 28px;
    }
    .tab-container-portfolio & {
        @include media-breakpoint-only(xs) {
            margin-top: 10px;
        }
    }
    .stage-module &, .stage-module-new & {
        justify-content: center;
        align-content: center;
        margin-top: 20px;
        @include media-breakpoint-up(md) {
            margin-top: 30px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: -50px;
        }
    }
    .stage-module-highlight .stage-module &,
    .stage-module-suggestion .stage-module &,
    .stage-module-highlight .stage-module-new &,
    .stage-module-suggestion .stage-module-new & {
        @include media-breakpoint-up(xl) {
            margin-top: -120px;
        }
    }
    .dot {
        -webkit-transition: width .5s, height .5s, border-radius .5s, top .5s;
        -moz-transition: width .5s, height .5s, border-radius .5s, top .5s;
        -ms-transition: width .5s, height .5s, border-radius .5s, top .5s;
        -o-transition: width .5s, height .5s, border-radius .5s, top .5s;
        transition: width .5s, height .5s, border-radius .5s, top .5s;
        cursor: pointer;
        display: inline-block;
        height: 8px;
        width: 8px;
        margin: 0 6px 0 0;
        background-color: $color-grenke-black;
        border-radius: 50%;
        top: 0;
        &.active {
            position: relative;
            top: 3px;
            height: 12px;
            width: 12px;
            border-radius: 50%;
        }
        .stage-module & {
            border: 1px solid $color-grenke-white;
        }
        .stage-module &, .portfolio-module & {
            top: 0;
            margin: 0 7px;
            &.active {
                top: 0;
            }
        }
    }
}

.slick-arrow {
    cursor: pointer;
    &.next {
        img {
            -webkit-transform: scale(-1);
            -moz-transform: scale(-1);
            -ms-transform: scale(-1);
            -o-transform: scale(-1);
            transform: scale(-1);
        }
    }
}
