#contact-detail-page {
    #contact-detail-form[data-language="fr_LU"],
    #contact-detail-form[data-language="de_LU"],
    #contact-detail-form[data-language="en_LU"] {
        #requestInterest-col {
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Career"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                display: block;
            }
        }
        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }
        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                [data-dropdown-value="Dealer_Sales_Support"] {
                    display: block;
                }
            }
        }
        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"] {
                    display: block;
                }
            }
            .upload-row {
                display: flex;
                [data-upload-title="supplier"],
                [data-upload-btn="supplier"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"]{
                    display: block;
                }
            }
            .row-customer-service {
                display: flex;
            }
            [data-customer-service-item="CustomerNumber"],
            [data-customer-service-item="ContractNumber"] {
                display: block;
            }
        }

        #imprint-grenke-ag, .imprint-row, .phone-contact-number-grenke-ag {
            display: block;
        }

        [data-form-input-name="VATNumber"],
        [data-form-input-name="CompanyRegistrationNo"] {
            display: block;
        }

        #phone-time-wrapper-js {
            .time-slot {
                display: block;
                max-width: 100%;
                flex: 0 0 100%;
            }
            .time-slot+.time-slot{
                margin-top: 20px;
            }
        }
    }
}