.sticky {
  position: fixed;
  right: 0;
  top: 80px; //15vh;
  z-index: 900;
  @include media-breakpoint-up(sm) {
    top: 33vh;
  }
  .sticky-container {
    background-color: $color-grenke-black;
    height: 55px;
    width: 55px;
    position: relative;
    .sticky-hover {
      right: -265px;
      top: 0;
      width: 265px;
      height: 55px;
      background-color: $color-grenke-black;
      color: $color-grenke-white;
      position: absolute;
      padding: 16px 0 16px 20px;
      transition: right 0.5s;
      &.mail {
        span {
          position: relative;
          color: $color-grenke-white;
          &:after {
            width: 16px;
            height: 16px;
            position: absolute;
            content: "";
            top: 1px;
            right: -24px;
            background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_white.svg");
            background-repeat: no-repeat;
            background-size: 16px;
            rotate: 90deg;
          }
        }
      }
    }
    &:hover {
      cursor: pointer;
      @include media-breakpoint-up(md) {
        .sticky-hover {
          right: 0;
        }
        @media (hover: none) {
          .sticky-hover {
            right: -265px;
          }
        }
      }
    }
    &.open {
      &:hover {
        cursor: pointer;
        @include media-breakpoint-up(md) {
          .sticky-hover {
            right: -265px;
          }
        }
      }
    }
    .icon-container {
      width: 55px;
      height: 55px;
      background-size: 24px;
      background-repeat: no-repeat;
      position: absolute;
      background-position: center;
      z-index: 9;
      &.phone {
        background-image: url("../website/medias/v2/icons/GRENKE_Icon_multimedia_phone_2.svg");
        background-color: $color-grenke-black;
        z-index: 99;
        &.inactive {
          display: none;
        }
      }
      &.mail {
        background-image: url("../website/medias/v2/icons/GRENKE_Icon_digital_mail_1.svg");
        background-color: $color-grenke-black;
      }

      &.help {
        background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_questionmark_1.svg");
        background-color: $color-grenke-black;
      }
      &.close-btn {
        background-image: url("../website/medias/v2/icons/GRENKE_Icon_sign_cross_line_1_white.svg");
        background-color: $color-grenke-black;
        background-size: 24px;
        display: none;
        z-index: 99;
        &.active {
          display: block;
        }
      }
    }
    &.mail,
    &.help {
      margin-top: 5px;
    }
  }
  .sticky-info-container {
    background-color: $color-grenke-white;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
    padding: 20px 75px 20px 20px;
    width: 330px;
    height: auto;
    position: absolute;
    top: 0;
    right: -340px;
    z-index: 10;
    transition: right 0.5s;
    .sticky-info-wrapper {
      max-height: 75vh;
      overflow-y: auto;
      @include media-breakpoint-up(md) {
        max-height: 50vh;
        overflow-y: auto;
      }
    }
    .sticky-phone-number {
      margin: 0 0 10px;
    }
    .sticky-headline + .sticky-phone-number {
      margin-top: 20px;
    }
    .sticky-headline + .sticky-phone-description {
      margin-top: 20px;
    }
    .sticky-phone-description + .sticky-phone-number {
      margin-top: 10px;
    }
    .sticky-opening-hours + .sticky-phone-description,
    .sticky-phone-number + .sticky-phone-description {
      margin-top: 20px;
    }
    &.active {
      right: 0;
    }
  }
}

header:has(.notification-banner) ~ .sticky {
  top: 200px;
  @include media-breakpoint-up(sm) {
    top: 33vh;
  }
}
