.swiper-container-horizontal {
    overflow: hidden;
    .portfolio-module &.tab-container-navi {
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
        }
    }
    .tab-text-image-module &.tab-container-navi {
        margin-bottom: 17px;
        @include media-breakpoint-up(xl) {
            margin-bottom: 27px;
        }
    }
}

.swiper-slide {
    flex-shrink: 0;

    .tab-item {
      .copy {
        color: $color-grenke-grey-dark-text;

        &:hover {
          color: $color-grenke-black;
        }
      }
    }

    &.active {
      .tab-item {
        .copy {
          color: $color-grenke-black;
        }
      }
    }

    .portfolio-module .tab-container-portfolio &, .tab-text-image-module .tab-container-tab-image & {
        opacity: 0 !important;

        &.swiper-slide-active {
            opacity: 1 !important;
        }
    }

    .portfolio-module .tab-container-navi &, .tab-text-image-module .tab-container-navi & {
        padding: 0 12px 10px;
        width: auto;

        .tab-item {
            padding: 10px 25px;
            position: relative;
            width: 100%;
            cursor: pointer;
            color: $color-grenke-black;
        }
        &:hover {
            border-bottom: 2px solid $color-grenke-black;

            .tab-item {
                color: $color-grenke-black;
            }
        }

        &.active {
            border-bottom: 2px solid $color-grenke-black;
            .tab-item {
                color: $color-grenke-black;
            }
        }
    }
    .tab-text-image-module .tab-container-navi & {
        @include media-breakpoint-up(xl) {
            padding: 1px 1px 7px 0;
        }
    }
}
.swiper-button-prev, .swiper-button-next {
    position: absolute;
    z-index: 2;
    top: 8px;
    margin: 0;
    background: none;
    align-items: center;
    display: flex;
    cursor: pointer;
    width: 32px;
    height: 32px;
    &:focus, &:active {
        outline: none;
    }
    &:after {
        display: none;
    }
}

.swiper-button-prev {
    left: 15px;
}

.swiper-button-next {
    right: 15px;
}

.swiper-button-disabled {
    display: none !important;
}

:is(.tab-text-image-module, .tab-search-page, .portfolio-module) .tab-container-navi {
    .swiper-button-icon {
        background: url("../website/medias/v2/icons/GRENKE_Icon_sign_arrow-up_1_black.svg") center no-repeat;
        width: 32px;
        height: 32px;
    }
    .swiper-button-icon.prev {
        transform: rotate(-90deg);
    }
    .swiper-button-icon.next {
        transform: rotate(90deg);
    }
}
