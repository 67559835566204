.contact-module {
    @include std-bottom-margin(false);
    .employee-name + .job-title {
        margin-top: 10px;
    }
    .job-title + .copy {
        margin-top: 20px;
    }
    .copy + .copy {
        margin-top: 10px;
    }
    .copy:last-child {
        margin-bottom: 30px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
    .img-wrapper {
        display: flex;
        position: relative;
        z-index: 1;
        margin: 0 auto;
        width: 160px;
        height: 160px;

        @include media-breakpoint-up(sm) {
            width: 100%;
            // sm-container: 540px
            height: calc(540px/12*4 - 30px);
        }
        @include media-breakpoint-up(md) {
            // md-container: 720px
            height: calc(720px/12*4 - 30px);
        }
        @include media-breakpoint-up(lg) {
            // lg-container: 960px
            height: calc(960px/12*3 - 30px);
        }
        @include media-breakpoint-up(xl) {
            // xl-container: 1310px
            height: calc(1310px/12*3 - 30px);
        }
    }
    .content-container {
        margin-top: -110px;
        z-index: 0;
        @include media-breakpoint-up(sm) {
            margin: -100px 0 0 auto;
        }
        @include media-breakpoint-up(md) {
            margin-top: -170px;
        }
        @include media-breakpoint-up(lg) {
            min-height: 210px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: -248px;
            min-height: 298px;
        }
    }
    .content-wrapper {
        background-color: $color-grenke-grey-background;
        padding: 130px 30px 30px 30px;
        @include media-breakpoint-up(sm) {
            padding: 30px 30px 30px 15px;
        }
        @include media-breakpoint-up(md) {
            padding: 30px 30px 30px 0px;
        }
        @include media-breakpoint-up(lg) {
            padding: 30px;
            height: 100%;
        }
        @include media-breakpoint-up(xl) {
            padding: 50px;
        }
        @include media-breakpoint-up(lg) {
            & > .row {
                min-height: 160px;
            }
        }
        @include media-breakpoint-up(xl) {
            & > .row {
                min-height: 198px;
            }
        }
    }
}
