#contact-detail-page {
  #contact-detail-form[data-language="pl_PL"] {
    // show interests-values
    #requestInterest-col {
      [data-dropdown-value="Consulting"],
      [data-dropdown-value="Offers"],
      [data-dropdown-value="Customer_Service"],
      [data-dropdown-value="Press"],
      [data-dropdown-value="Career"],
      [data-dropdown-value="Other"],
      [data-dropdown-value="Cooperation"] {
        display: block;
      }
    }

    // show topic-dropdown
    &[data-form-interest="Consulting"],
    &[data-form-interest="Offers"],
    &[data-form-interest="Customer_Service"],
    &[data-form-interest="Cooperation"] {
      #requestTopic-col {
        display: block;
      }
    }

    [data-form-input-name="TaxID"] {
      display: block;
      margin-top: 40px;
    }

    &[data-form-interest="Career"],
    &[data-form-interest="Other"],
    &[data-form-interest="Press"] {
      [data-form-input-name="TaxID"] {
        display: none;
      }
    }

    &[data-form-topic="Liquidity_Entrepreneurs"],
    &[data-form-topic="Liquidity_Startups"],
    &[data-form-topic="Factoring"],
    &[data-form-topic="Dealer_Advanced_Financing"] {
      #imprint-grenke-factoring {
        display: block;
      }
    }

    &[data-form-topic="Asset_Financing"],
    &[data-form-topic="Leasing_And_Rental"],
    &[data-form-topic="Leasing"],
    &[data-form-topic="Dealer_Sales_Support"],
    &[data-form-interest="Press"],
    &[data-form-interest="Other"] {
      #imprint-grenke-ag {
        display: block;
      }
    }

    // display default number
    .phone-contact-number.phone-contact-number-grenke-leasing {
      display: block;
    }

    &[data-form-interest="Consulting"][data-form-topic="Liquidity_Entrepreneurs"],
    &[data-form-interest="Consulting"][data-form-topic="Liquidity_Startups"],
    &[data-form-interest="Offers"][data-form-topic="Factoring"],
    &[data-form-interest="Customer_Service"][data-form-topic="Factoring"],
    &[data-form-interest="Career"][data-form-topic="Factoring"],
    &[data-form-interest="Cooperation"][data-form-topic="Dealer_Advanced_Financing"] {
      .phone-contact-number {
        &.phone-contact-number-grenke-leasing {
          display: none;
        }

        &.phone-contact-number-grenke-factoring {
          display: block;
        }
      }
    }

    &[data-form-interest="Customer_Service"][data-form-topic="Leasing"] {
      .phone-contact-number {
        &.phone-contact-number-grenke-leasing {
          display: none;
        }

        &.phone-contact-number-customer-service-leasing {
          display: block;
        }
      }
    }

    &[data-form-interest="Career"][data-form-topic="Leasing"] {
      .phone-contact-number {
        &.phone-contact-number-grenke-leasing {
          display: none;
        }

        &.phone-contact-number-grenke-career-leasing {
          display: block;
        }
      }
    }

    &[data-form-interest="Press"] {
      .phone-contact-number {
        &.phone-contact-number-grenke-leasing {
          display: none;
        }

        &.phone-contact-number-grenke-ag-press {
          display: block;
        }
      }
    }

    &[data-form-interest="Other"] {
      .phone-contact-number {
        &.phone-contact-number-grenke-leasing {
          display: none;
        }

        &.phone-contact-number-grenke-other {
          display: block;
        }
      }
    }

    &[data-form-interest="Customer_Service"][data-form-topic="Leasing"],
    &[data-form-interest="Customer_Service"][data-form-topic="Factoring"] {
      .row-customer-service {
        display: flex;

        [data-customer-service-item="CustomerNumber"], [data-customer-service-item="ContractNumber"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Consulting"] {
      #requestTopic-col {
        // show topics
        [data-dropdown-value="Liquidity_Entrepreneurs"],
        [data-dropdown-value="Liquidity_Startups"],
        [data-dropdown-value="Asset_Financing"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Cooperation"] {
      #requestTopic-col {
        [data-dropdown-value="Dealer_Sales_Support"],
        [data-dropdown-value="Dealer_Advanced_Financing"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Offers"] {
      #requestTopic-col {
        // show topics
        [data-dropdown-value="Leasing_And_Rental"],
        [data-dropdown-value="Factoring"] {
          display: block;
        }
      }

      &[data-form-topic="Leasing_And_Rental"] {
        .upload-row {
          display: flex;
        }

        [data-upload-title="supplier"], [data-upload-btn="supplier"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Customer_Service"] {
      #requestTopic-col {
        // show topics
        [data-dropdown-value="Leasing"],
        [data-dropdown-value="Factoring"] {
          display: block;
        }
      }
    }

    &[data-form-interest="Career"] {
      #requestTopic-col {
        display: block;
        // show topics
        [data-dropdown-value="Leasing"],
        [data-dropdown-value="Factoring"] {
          display: block;
        }
      }
    }

    #phone-time-wrapper-js {
      .time-slot {
        display: block;
      }
    }
  }
}
