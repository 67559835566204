#news-overview-page {
    .filter-container {
        margin-bottom: 50px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 60px;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 90px;
        }
    }
    .national-select {
        margin-bottom: 9px;
        @include media-breakpoint-up(md) {
            margin: auto 0;
        }
    }
    .col-filter-reset {
        &.national-comparison {
            margin-top: 5px;
            margin-bottom: 39px;
            @include media-breakpoint-up(md) {
                margin-top: 30px;
                margin-bottom: 0;
            }
        }
        .filter-reset {
            height: 100%;
            @include formfield-copy;
            color: $color-grenke-black;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .col-filter-category, .col-filter-reset, .col-filter-year {
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
    .col-filter-search-input {
        @include media-breakpoint-only(md) {
            margin-top: 30px;
        }
    }
    .no-documents-js {
        display: none;
        color: $color-grenke-black;
        @include std-bottom-margin(false);
    }
    .back-to-top-height {
        height: 0px;
        margin-top: 88px;
    }
    .text-box-container .international {
        position: relative;
        padding-left: 20px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 14px;
            width: 14px;
            background: no-repeat center center url("../website/medias/v2/icons/GRENKE_Icon_Language_1_grey.svg");
            background-size: cover;
        }
    }
}
