// styles for hu_HU
#contact-detail-page {

    #contact-detail-form[data-language="hu_HU"] {
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                display: block;
            }
        }

        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Liquidity_Startups"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                display: block;

                [data-dropdown-value="Dealer_Sales_Support"],
                [data-dropdown-value="Dealer_Advanced_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Customer_Service"],
        &[data-form-interest="Career"],
        &[data-form-interest="Other"] {

            #requestTopic-col {
                display: block;

                [data-dropdown-value="Leasing"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }
        }

        [data-form-input-name="VATNumber"] {
            display: block;
        }

        &[data-form-interest="Career"],
        &[data-form-interest="Other"] {
            [data-form-input-name="VATNumber"] {
                display: none;
            }
        }

        &[data-form-topic="Asset_Financing"],
        &[data-form-topic="Leasing_And_Rental"],
        &[data-form-topic="Dealer_Sales_Support"],
        &[data-form-topic="Leasing"] {
            #imprint-grenke-ag {
                display: block;
            }
        }

        &[data-form-topic="Factoring"],
        &[data-form-topic="Liquidity_Entrepreneurs"],
        &[data-form-topic="Liquidity_Startups"],
        &[data-form-topic="Dealer_Advanced_Financing"] {
            #imprint-grenke-factoring {
                display: block;
            }
        }

        &[data-form-topic] {
            .phone-contact-number {
                display: none;
            }
            .phone-contact-number-grenke-factoring {
                display: block;
            }
        }


        &[data-form-interest="Consulting"][data-form-topic="Asset_Financing"],
        &[data-form-interest="Offers"][data-form-topic="Leasing_And_Rental"],
        &[data-form-interest="Cooperation"][data-form-topic="Dealer_Sales_Support"],
        &[data-form-interest="Customer_Service"][data-form-topic="Leasing"],
        &[data-form-interest="Other"][data-form-topic="Leasing"] {
            .phone-contact-number-grenke-factoring {
                display: none;
            }

            .phone-contact-number-grenke-leasing {
                display: block;
            }
        }

        &[data-form-interest="Career"][data-form-topic="Factoring"] {
            .phone-contact-number-grenke-factoring {
                display: none;
            }

            .phone-contact-number-grenke-career-factoring {
                display: block;
            }
        }

        &[data-form-interest="Career"][data-form-topic="Leasing"] {
            .phone-contact-number-grenke-factoring {
                display: none;
            }

            .phone-contact-number-grenke-career-leasing {
                display: block;
            }
        }

        &[data-form-interest="Customer_Service"][data-form-topic="Factoring"] {
            .phone-contact-number-grenke-factoring {
                display: none;
            }

            .phone-contact-number-customer-service-factoring {
                display: block;
            }
        }

        &[data-form-interest="Other"][data-form-topic="Factoring"] {
            .phone-contact-number-grenke-factoring {
                display: none;
            }

            .phone-contact-number-grenke-other-factoring {
                display: block;
            }
        }

        &[data-form-interest="Customer_Service"][data-form-topic="Factoring"],
        &[data-form-interest="Customer_Service"][data-form-topic="Leasing"] {
            .row-customer-service {
                display: flex;

                [data-customer-service-item="CustomerNumber"] {
                    display: none;
                }

                [data-customer-service-item="ContractNumber"] {
                    display: block;
                }
            }
        }

        &[data-form-topic="Leasing_And_Rental"] {
            .upload-row {
                display: flex;
            }
        }
        &[data-form-topic="Leasing_And_Rental"] {
            [data-upload-title="supplier"], [data-upload-btn="supplier"] {
                display: block;
            }
        }

        #optin-dataprotectionagreement {
            display: block;
        }
    }
}