$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1280px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1310px
);

$border-color: #000000;
