.eloqua-form-with-layout{
  #js-warning {
    margin-top: $bottom-gap-module-xs + 90px;
    @include media-breakpoint-up(sm) {
      margin-top: $bottom-gap-module-sm + 40px;
    }
    @include media-breakpoint-up(md) {
      margin-top: $bottom-gap-module-md + 20px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: $bottom-gap-module-lg + 60px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: $bottom-gap-module-xl + 30px;
    }
  }
}
