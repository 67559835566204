// styles for cs_CZ

#contact-detail-page {
    #contact-detail-form[data-language="cs_CZ"] {
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                display: block;
            }
        }
        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                display: block;
            }

            #requestTopic-col {
                [data-dropdown-value="Dealer_Sales_Support"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"] {
                    display: block;
                }
            }

            &[data-form-topic="Leasing_And_Rental"] {
                .upload-row {
                    display: flex;
                    [data-upload-title="supplier"],
                    [data-upload-btn="supplier"] {
                        display: block;
                    }
                }
            }
        }

        &[data-form-interest="Customer_Service"][data-form-topic="Leasing"]{
            .row-customer-service {
                display: flex;
            }
            [data-customer-service-item="CustomerNumber"],
            [data-customer-service-item="ContractNumber"] {
                display: block;
            }
        }

        #phone-time-wrapper-js {
            .time-slot {
                display: block;
            }
        }
        #imprint-grenke-ag, .imprint-row, .phone-contact-number-grenke-ag {
            display: block;
        }

        &[data-form-interest="Customer_Service"] {
            .phone-contact-number-grenke-leasing {
                display: block;
            }
            .phone-contact-number-grenke-ag {
                display: none;
            }
        }
    }
}



