// styles for it_IT MLA
#contact-detail-page {
    #contact-detail-form[data-language="it_IT"][data-campaign-name="mla"] {
        .time-slot {
            display: block;
        }

        [data-form-input-name="City"] {
            display: none;
        }

        [data-form-input-name="VATNumber"] {
            display: block;
        }
    }
}
