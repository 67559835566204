.iframe-module {
  @include std-bottom-margin(true);

  .container:has(iframe.euroland) {
    height: auto;
  }

  iframe {
    border: 0;
    width: 1px;
    min-width: 100%;
  }

  iframe.deutsche-boerse {
    overflow: hidden;
    height: 1111px;

    @include media-breakpoint-up(lg) {
      height: 1038px;
    }

    @include media-breakpoint-up(xl) {
      height: 914px;
    }
  }

  iframe.equitystory-formular {
    width: 100%;
  }

  iframe.euroland {
    border: none;
    overflow: hidden;
  }
}
