// styles for de_CH
#contact-detail-page {
    #contact-detail-form[data-language="de_CH"],
    #contact-detail-form[data-language="it_CH"],
    #contact-detail-form[data-language="fr_CH"] {
        // show interests-values
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Other"],
            [data-dropdown-value="Cooperation"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Customer_Service"],
        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                display: block;
            }
        }

        // show upload
        &[data-form-topic="Leasing_And_Rental"] {
            .upload-row {
                display: flex;
            }
        }

        // show upload supplier-headline and supplier-button
        //
        &[data-form-topic="Leasing_And_Rental"] {
            [data-upload-title="supplier"], [data-upload-btn="supplier"] {
                display: block;
            }
        }

        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                // show topics
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Usage_Solutions"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                [data-dropdown-value="Dealer_Managed_Service"],
                [data-dropdown-value="Dealer_Sales_Support"],
                [data-dropdown-value="Dealer_Advanced_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-topic="Leasing_And_Rental"],
        &[data-form-topic="Leasing"],
        &[data-form-topic="Asset_Financing"],
        &[data-form-topic="Usage_Solutions"],
        &[data-form-topic="Dealer_Managed_Service"],
        &[data-form-topic="Dealer_Sales_Support"],
        &[data-form-topic="Dealer_Advanced_Financing"],
        &[data-form-interest="Press"],
        &[data-form-interest="Other"],
        {
            #imprint-grenke-ag {
                display: inline;
            }
        }

        &[data-form-topic="Factoring"],
        &[data-form-topic="Liquidity_Entrepreneurs"] {
            #imprint-grenke-factoring {
                display: inline;
            }
        }

        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }

            &[data-form-topic="Leasing"] {
                .upload-row {
                    display: flex;
                }
            }
        }

        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }

            .row-customer-service {
                display: flex;
            }

            [data-customer-service-item="CustomerNumber"],
            [data-customer-service-item="ContractNumber"] {
                display: block;
            }
        }

        // hide initial phonenumbers
        .phone-contact-number {
            &.phone-contact-number-grenke-leasing {
                display: block;
            }
        }

        // show press phone number
        &[data-form-topic="Factoring"],
        &[data-form-topic="Liquidity_Entrepreneurs"] {
            .phone-contact-number-grenke-leasing {
                display: none;
            }
            .phone-contact-number-grenke-factoring {
                display: block;
            }
        }
        #phone-time-wrapper-js {
            .time-slot {
                display: block;
            }
        }
    }
}