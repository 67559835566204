.text-module {
    @include std-bottom-margin(false);
    &.reduce-top {
        margin-top: $top-gap-modul-reduce-xs;
        @include media-breakpoint-up(sm) {
            margin-top: $top-gap-modul-reduce-sm;
        }
        @include media-breakpoint-up(md) {
            margin-top: $top-gap-modul-reduce-md;
        }
        @include media-breakpoint-up(lg) {
            margin-top: $top-gap-modul-reduce-lg;
        }
        @include media-breakpoint-up(xl) {
            margin-top: $top-gap-modul-reduce-xl;
        }
    }
    .headline-wrapper + .copytext-wrapper {
        margin-top: 20px;
        @include media-breakpoint-up(md) {
            margin-top: 30px;
        }
    }
    .copytext-box + .copytext-box {
        margin-top: 30px;
        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    }
    .copytext-wrapper + .button-wrapper {
        margin-top: 30px;
    }
}
