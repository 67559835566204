.news-teaser {
  position: relative;
  @include std-bottom-margin(false);

  .news-events &,
  .investor-relations & {
    margin-bottom: 0;
  }

  &.flyout-news-teaser {
    margin-bottom: 0;

    .content-wrapper {
      flex-direction: column;

      p {
        &.navigation-news-headline {
          max-height: calc(22px * 1);
          overflow: hidden;
          margin: 20px 0 10px;
        }
      }
    }

    .img-box {
      height: 205px;
      margin-bottom: 0;
    }
  }

  #news-overview-page & {
    margin-bottom: 0;
    overflow: hidden;
  }

  .date-box {
    display: inline-block;
    background-color: $color-grenke-black-85;
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 9;
    padding: 10px;
    @include media-breakpoint-up(sm) {
      right: 15px;
    }
    @include media-breakpoint-up(lg) {
      padding: 15px;
    }
    @include media-breakpoint-up(xl) {
      padding: 20px;
    }
  }

  .navigation-news {
    .date-box {
      padding: 8px;

      .date-hero {
        line-height: 0.9;

        @include media-breakpoint-up(lg) {
          font-size: 40px;
        }
        @include media-breakpoint-up(xl) {
          font-size: 45px;
        }
      }

      .date-sub {
        margin-top: 3px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  h3 {
    overflow: hidden;

    &.dotdotdot {
      // 26px line-height
      max-height: calc(26px * 4);
      @include media-breakpoint-up(sm) {
        // 28px line-height
        max-height: calc(28px * 4);
      }
      @include media-breakpoint-up(xl) {
        // 38px line-height
        max-height: calc(38px * 4);
      }
    }
  }

  p {
    &.dotdotdot.navigation-news-headline {
      max-height: 22px;
      @include media-breakpoint-up(sm) {
        max-height: 26px;
      }
      @include media-breakpoint-up(lg) {
        max-height: 32px;
      }
      @include media-breakpoint-up(xl) {
        max-height: 32px;
      }
    }
  }

  .international,
  .national {
    margin-bottom: 10px;
  }

  .international,
  .international-news,
  .national,
  .national-news {
    position: relative;
    padding-left: 20px;
    color: $color-grenke-black-icon;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      background-size: cover;
    }
  }

  .international-news,
  .national-news {
    margin-top: 22px;
    margin-bottom: -15px;
  }

  .international:before,
  .international-news:before {
    background: no-repeat center center url("../website/medias/v2/icons/GRENKE_Icon_Language_1_grey.svg");
  }

  .national:before,
  .national-news:before {
    background: no-repeat center center url("../website/medias/v2/icons/GRENKE_Icon_Location-pin-outline_1_grey.svg");
    background-size: contain;
  }

  div.dotdotdot p {
    display: none;

    &:first-child {
      max-height: calc(23px * 4);
      display: block;
      overflow: hidden;
      @include media-breakpoint-up(sm) {
        max-height: calc(26px * 4);
      }
      @include media-breakpoint-up(xl) {
        max-height: calc(28px * 4);
      }
    }
  }

  .content-box {
    &:hover {
      .btn-tertiary {
        left: 10px;
      }
    }
  }

  #news-overview-page & .two-column-wrapper {
    &:nth-child(n + 3) {
      margin-top: 80px;
    }
  }

  .js-open-article,
  .js-a-holder {
    cursor: pointer;
  }
}

.article-overview {
  margin-bottom: 70px;
}

#news-overview-page #news-overview-container {
  @include std-bottom-margin(false);
}
