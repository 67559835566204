.infographics-module {
  @include std-bottom-margin(false);
}

.infographics-module {
  .img-box {
    &.landscape {
      height: auto;
      //@include media-breakpoint-up(sm) {
      //    height: 219px;
      //}
      //@include media-breakpoint-up(md) {
      //    height: 293px;
      //}
      //@include media-breakpoint-up(lg) {
      //    height: 391px;
      //}
      //@include media-breakpoint-up(xl) {
      //    height: 520px;
      //}
      .item-img {
        width: 100%;
        height: auto;
      }
    }
    &.portrait {
      height: 345px;
      width: 345px;
      margin: 0 auto;
      @include media-breakpoint-up(sm) {
        height: 256px;
        width: 256px;
      }
      @include media-breakpoint-up(md) {
        height: 346px;
        width: 346px;
      }
      @include media-breakpoint-up(lg) {
        height: 465px;
        width: 465px;
      }
      @include media-breakpoint-up(xl) {
        height: 622px;
        width: 622px;
      }
    }
  }
  .caption {
    margin: 20px 0 30px;
    @include media-breakpoint-up(xl) {
      margin: 20px 0 50px;
    }
  }
  .copy-text-two {
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  h3 {
    margin-top: 30px;
    margin-bottom: 10px;
    @include media-breakpoint-up(sm) {
      margin-top: 40px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 50px;
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: 20px;
    }
  }
  .caption + h3 {
    margin-top: 0;
  }
  .button-wrapper {
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
  }
}
